.pools {
  display: flex;
  flex-direction: column;
  gap: 40px;

  & select {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    height: 40px;
    border-radius: 5px;
    width: 100%;
  }

  &-table {
    background-color: white;
    height: 100%;
    text-align: center;

    & thead {
      border: none;

      border-bottom: 1px solid #E9E9E9;

      & tr {

        & th {
          text-align: center;
          line-height: 20px;
          color: #9A1032;
        }
      }
    }

    & tbody {
      border-top: none !important;
      box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);

      & tr:nth-child(odd) {
        background: #F0F2F5;
      }
    }
  }

  &_header {
    font-size: 24px;
    margin: 0;
  }

  &_container {
    display: flex;
    justify-content: space-between;

    &-select {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }

    &-header {
      margin: 0;
    }
  }
}

