.react-paginate {
  display: flex;
  justify-content: center;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none !important;
  }

  li {
    //display: flex;
    //justify-content: center;
    //align-items: center;
    list-style-type: none !important;
    border-radius: 100%;
    border: 1px solid var(--main-color);
    color: var(--main-text-color);

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      width: 40px;
      color: var(--main-text-color);
      text-decoration: none;
    }

    &.selected {
      background: var(--main-color);
      color: #fff;
    }

    &.break {
      border: none;
    }

    &:not(&:last-child) {
      margin-right: 10px;
    }
  }
}
