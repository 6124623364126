.info-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.inventory-info {
  display: flex;
  flex-direction: column;
  gap: 18px;
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding: 5px;

  &_heading {
    display: flex;
    justify-content: center;
  }
  &_fields {
    display: flex;
    justify-content: space-between;
  }
}
