@mixin main-button($reverse-colors: false) {
  $color: var(--main-color);
  $color-disabled: var(--grey);
  $color-active: #fff;

  @if ($reverse-colors) {
    $color: #fff;
    $color-active: var(--main-color);

    border: 1px solid var(--main-color);
  }

  @if ($reverse-colors == false) {
    border: 0;
  }

  background: $color;
  height: 35px;
  border: thin solid $color;
  border-radius: 5px;
  color: $color-active;

  &:hover {
    background: $color-active;
    border: 1px solid $color;
    color: $color;
  }

  &:disabled {
    background: $color;
    color: $color-disabled;
  }
}

@mixin main-input {
  box-sizing: border-box;
  border: 1px solid #94A6BD;
  font-style: normal;
  color: #94A6BD;

  &.is-invalid {
    border-color: var(--main-color);
  }
}

@mixin reset-border-radius {
  border-radius: 0;
}

@mixin custom-scroll() {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(59, 74, 93, 0.1);
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(59, 74, 93, 0.3);
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(85, 85, 85, 1);
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
