@import "../../../../assets/styles/variables";

.edit-ticket-form {
  flex-wrap: wrap;
  gap: 16px;
  max-width: 782px;
  margin-top: 40px;
  flex-direction: row;
}

.custom-seat-type {
  display: block;
  background-color: #FFFFFF;
}

.select-error {
  border: 1px solid $error-red;
}

.tag-picker, .disclosure-picker {
  min-width: 250px;
  border: 1px solid #94A6BD;
  border-radius: 5px;
}

.disclosure-picker {
  height: auto !important;
}
