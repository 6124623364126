@import "src/assets/styles/variables";

.events .nav-tabs .nav-link {
  background: var(--white);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--main-color);
}

.events .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: var(--main-color);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--white);
}

.nav-tabs {
  display: flex;
  gap: 8px;
}

.not-mapped-event {
  width: 100%;
  text-align: center;
  color: $red;
}

.icons-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem
}
