.calendar-container {
  max-height: calc(100vh - 110px);
  overflow: auto;
  margin: 5px 5px 0 30px;
  padding: 0 30px 0 0;
  width: 100vw;
}

.filters-accordion-header-euro-calendar {
  padding: 0;

  button {
    &::after {
      display: none;
    }

    &::before {
      display: none;
    }

    pointer-events: none;
  }
}

@media (max-width: 1024px) {
  .calendar-container {
    margin: 5px 5px 0 90px;
  }
}
