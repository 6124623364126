@import "../../../../assets/styles/media.scss";

.customers-tag-picker {
  min-width: 100%;
  border: 1px solid $inputs-grey;
  border-radius: 5px;
  margin: 0.25rem 0;

  &:hover {
    border-color: $inputs-grey !important;
  }
}

.inputs-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.customer-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item-gap {
  gap: 10px;
  width: 100%;
}

.rs-picker-menu {
  z-index: 9999;
}

.error-message {
  color: red;
  margin-top: 5px;
}

.default-formik-input__error {
  z-index: 9;
}


@media (max-width: 1400px) {
  .inputs-container {
    input {
      max-width: 200px;
    }
  }
}

@media (max-width: 1220px) {
  .inputs-container {
    flex-direction: column;
  }
}

.modal-form {
  display: flex;
  flex-direction: column;
  gap: 42px;

  &__footer {
    margin: 80px 0 42px 0;
    display: flex;
    justify-content: flex-end;
    border-top: 1.5px solid $gray;
  }

  &__buttons-container {
    display: flex;
    gap: 16px;
    padding-top: 1rem;

    button {
      min-width: 140px;
    }
  }

  &__header {
    border-bottom: 1.5px solid $gray;

    &-title {
      text-align: left;
      margin: 0;
      font-family: $body-font;
      font-size: $semi-large-font-size;
      font-weight: $font-bold;
      color: $blue;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__inputs {
    display: flex;
    gap: 16px;

    &_multiple {
      flex-direction: column;
    }
  }

  &__input {
    &-container {
      display: flex;
      flex-basis: 33.33%;
      width: 100%;
      flex-direction: column;
      gap: 4px;
      justify-content: space-between;

      select {
        width: 100%;
        max-height: 35px;
        height: 100%;
        padding: 0.375rem 0.75rem;
        line-height: 1.5;
        border: 1px solid $inputs-grey;
        border-radius: 0.25rem;
        margin: 0.25rem 0;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &-title {
      margin: 0;
      color: $blue;
    }
  }
}

.custom-error {
  position: absolute;
  top: 0;
  right: 20px;
  color: #dc3545;
}

.error-relative {
  position: relative;
}

@include tablet-744 {
  .modal-form {
    &__inputs {
      flex-wrap: wrap;
    }

    &__input-container {
      flex-basis: 45%;
    }
  }
}

@include small-layout {
  .modal-form {
    &__input-container {
      flex-basis: 100%;
    }

    &__footer {
      justify-content: center;
    }

    &__buttons-container {
      flex-direction: column;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
}
