@import "../../../assets/styles/media.scss";

.teammates-table-container {
  overflow: auto;
}

.teammates {
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__head span {
    text-align: right;
    color: var(--main-color);
    cursor: pointer;
    padding-left: 1rem;
    text-decoration: underline;
  }

  &__table {
    border-collapse: separate;
    background-color: var(--white);
    padding: 25px 40px;
    box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
    border-radius: 5px;

    &__th {
      text-align: center;
      line-height: 20px;
      color: #9A1032;
    }

    &__td {
      vertical-align: middle;
      text-align: center;
      padding: 0.1rem 0.1rem !important;
      line-height: 50px;
    }

    &__action-button {
      background: none;
      border: none;
      padding: 0;

      &:hover {
        background: none;
        border: none;
      }

      &:focus {
        background: none;
        border: none;
        box-shadow: none !important;
      }

      &:active {
        background: none;
        border: none;
        box-shadow: none !important;
      }
    }

    &__action-button, .dropdown-toggle {
      &:after {
        content: none;
      }
    }
  }
}

@media (max-width: 995px) {
  .admin-users-page {
    margin: 0 auto;
  }
}

@include small-layout {
  .admin-users-page {
    &__title {
      color: var(--blue, #173454);
      font-weight: $font-bold;
      line-height: normal;
    }
  }

  .teammates {
    &__table {
      padding: 0 16px;
      width: 100%;

      th {
        min-width: 140px;
        padding: 16px;
        color: var(--red, #9A1032);
        text-align: center;
        line-height: normal;
      }

      td {
        color: var(--blue, #173454);
        text-align: center;
        line-height: normal;
      }
    }
  }

}
