@import url('https://fonts.googleapis.com/css2?family=Gothic+A1&family=Josefin+Slab:wght@700&family=Khula:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import "assets/styles/reset.css";
@import "rsuite/dist/rsuite-no-reset.min.css";

@import "assets/styles/variables.scss";
@import "assets/styles/validation.scss";
@import "assets/styles/mixins.scss";

@import "assets/styles/layout/header.scss";
@import "assets/styles/layout/paginate.scss";
@import "assets/styles/layout/typography.scss";

body {
  font-family: $table-font;
  font-size: $normal-font-size;
  font-weight: $font-normal;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #fff;
  }

  &::-webkit-scrollbar {
    width: var(--scroll-size);
    height: var(--scroll-size);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--scroll-color);
  }
}

th {
  font-weight: $font-normal;
}

.invalid-tooltip {
  background: var(--main-color);
  color: #fff;
  opacity: .9;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.clickable-word {
  cursor: pointer;
  font-family: 'Gilroy', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;

  color: #173454;
}

.main-color {
  color: var(--main-color);
}

.action-button {
  width: 100% !important;
}

.column {
  flex-direction: column;
  gap: 16px;
}

.table__filters {
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  position: sticky;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    &-column {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 8px;

      > *:not(input[type=checkbox]) {
        width: 100% !important;
        height: 2.375rem;
      }
    }

    & .default-formik-input, input:not(input[type=checkbox]), .app-check {
      width: 100%;
    }

    .react-datepicker__tab-loop {
      margin: 0 !important;
    }
  }

  input:not(input[type=checkbox]) {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    margin: 0 !important;

    &:focus-visible, :focus {
      outline: none;
      border: 1px solid #ced4da;
      box-shadow: none;
    }
  }

  select {
    display: block;
    background-color: var(--white);
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    width: 100%;

    &:focus-visible {
      outline: none;
    }
  }

  & .react-datepicker__tab-loop {
    min-width: 0;
  }
}

.accordion {
  &-button {
    font-family: $body-font;
    font-weight: $font-bold;
    font-size: $large-font-size;
    color: $blue;
    padding: .5rem;

    &:not(.collapsed) {
      color: $blue;
      background-color: var(--white);

      &:after {
        margin-left: 12px;
        background: center / contain url("assets/images/sell-page/AccordionArrow.svg") no-repeat;
        width: 10px;
        height: 8px;
      }
    }

    &:focus {
      border: none !important;
      outline: none !important;
      box-shadow: none !important;
    }

    &:after {
      margin-left: 12px;
      background: center / contain url("assets/images/sell-page/AccordionArrow.svg") no-repeat;
      width: 10px;
      height: 8px;
    }
  }

  &-body {
    padding: .5rem;
  }
}

select:focus-visible, input:focus-visible {
  outline: none;
}

.form-invalid {
  border: 2px solid red;
}

.container:not(.header__container) {
  max-width: 100% !important;
}

.btn {
  font-size: $normal-font-size;
}

.dropdown-item {
  font-size: $normal-font-size;
  line-height: normal;
}

.form-control {
  font-size: $normal-font-size;
}

.rs-picker-input {
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 35px;
  background-color: transparent;

  .rs-picker-toggle {
    height: 33px;
  }
}

.rs-picker-toggle {
  font-size: $normal-font-size;
}

.rs-toast-container {
  z-index: 1251;
}

.btn-primary, .show > .btn-primary.dropdown-toggle {
  border: none;
  background-color: transparent;
  padding: 0;

  &:is(:hover, :focus, :active) {
    border: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.nav {
  &-link {
    padding: .5rem;
  }
}

.nav .nav-item {
  box-shadow: 0 -4px 20px rgba(23, 52, 84, 0.15);
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.rs-picker-menu {
  max-width: 0;
  .rs-picker-check-menu-items {
    @include custom-scroll();
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  @include custom-scroll();
}
