.verify-email {
  &__title {
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #173454;
  }

  &__link {
    cursor: pointer;
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #173454;
  }
}
