$max-width: calc(50% - 10px);

.tc-account {
  display: flex;
  flex-direction: column;

  &_header {
    margin: 0;
  }

  &_input-name{
    margin: 0;
    padding-top: 10px;
    padding-bottom: 4px;
  }

  .input{
    display: flex;
    flex-direction: column;
    .default-formik-input {
      max-width: $max-width;
      .delete-btn {
        cursor: pointer;
      }
    }
  }

  .field {
    display: flex;
    flex-direction: column;
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;

    >button {
      max-width: $max-width;
    }
  }

}
