@import "src/assets/styles/variables";
@import "src/assets/styles/media";

.mobile-navigation {
  display: flex;
  flex-direction: column;
  position: relative;

  &__icon {
    background-image: url("../../../assets/images/other/burgerWhite.svg");
    width: 1.5rem;
    height: 1.5rem;

    &_close {
      width: 1rem;
      height: 1rem;
      cursor: pointer;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.menu-closed {
  display: none;
  transition: height 0.5s ease-in-out;
}

.menu-open {
  z-index: 2;
  display: block;
  height: 100vh;
  max-width: 432px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: $white;
  padding: 0 2rem;

  a {
    color: $red;
  }
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1.5rem 0;
}

.link-container {
  padding: 0;

  a {
    color: $white;
    font-size: $semi-large-font-size;
    font-weight: $font-normal;
    text-decoration: none;
    font-family: $body-font;

    &:hover {
      color: $red;
    }
  }

  span {
    padding: 1.5rem 0;

    a {
      color: $black;
    }

    &:first-child {
      border-top: thin solid $inputs-grey !important;
    }

    &:last-child {
      border-bottom: thin solid $inputs-grey;
      border-top: none !important;
    }
  }
}

.accordion-container {
  .accordion-item {
    border: none;

    button {
      margin: 0;
      padding: 1.5rem 0;
      border-bottom: thin solid $inputs-grey !important;
      color: $black;
      font-size: $semi-large-font-size;
      font-weight: $font-normal;
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $red;
      }

      &:after {
        transform: rotate(0deg);
        width: 0.75rem;
        height: 0.75rem;
        background-image: url("../../../assets/images/other/menuArrow.svg");
        margin: 0 0 0 auto;
      }

      &[aria-expanded="true"] {
        color: $red;
        font-weight: $font-semi-bold;

        &:after {
          transform: rotate(90deg);
          background-image: url("../../../assets/images/other/menuArrowRed.svg");
        }
      }
    }

    .accordion-body {
      display: flex;
      flex-direction: column;
      padding: 0;

      p {
        cursor: pointer;
        color: $black;
        padding: 1.125rem 1rem;
        font-weight: $font-normal;
        font-size: $normal-font-size;
        font-family: $body-font;

        &:hover {
          background-color: $inputs-grey;
        }
      }
    }
  }
}

.header-wrapper.header-opened-white .mobile-navigation__icon_close {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;
  background-image: url("../../../assets/images/other/close.svg");
}

@include small-layout {
  .menu-open {
    max-width: 430px;
  }
}
