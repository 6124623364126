@import "../../../assets/styles/mixins";
@import "../../../assets/styles/media.scss";

.sidebar-overlay {
  position: fixed;
  top: 70px;
  left: 75px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  display: block;
}

.user-sell-page {
  padding: 1rem .5rem 0 .5rem;
  min-width: calc(100% - var(--sell-page-filter-max-size));
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__dropdown-btn {
    background-color: $white;
  }

  &__dropdown-btn:is(:hover, :focus, :active) {
    background-color: $white;
  }

  &__top-block {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;

    p {
      margin: 0;
      font-family: $body-font;
    }

    &-title {
      font-weight: $font-bold;
      font-size: $larger-font-size;
      line-height: 44px;
      color: #173454;
    }

    &-sub-title {
      font-size: 18px;
      line-height: 30px;
      color: #333333;
      margin: 0 0 10px 0;
    }
  }

  &__filters-block {
    max-width: 640px;
  }

  &__filter-label {
    line-height: 17px;
    color: #173454;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
  }

  &__filter-input {
    height: 52px;
    border: 1px solid #94A6BD;
    border-radius: 5px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .75rem .75rem;
    background: var(--blue);
    border-radius: 8px 8px 0 0;
    color: var(--white);

    &-title {
      font-weight: $font-bold;
      font-family: $body-font;
      font-size: $large-font-size;
      line-height: 28px;
      margin: 0;
    }

    &-filter {
      line-height: 24px;

      &-name {
        margin-right: 16px;
      }

      &-sort {
        padding: 15px 16px;
        max-width: 150px;
        height: 36px;
        border-radius: 5px;
      }
    }
  }

  &__table-block {

    flex: 1;
    display: flex;
    flex-direction: column;
    justify-items: stretch;
    overflow: hidden;

    &-upload-file {
      display: flex;
      align-items: center;
      font-family: $body-font;
      font-size: $normal-font-size;

      a {
        text-align: right;
        color: var(--main-color);
      }

      span {
        text-align: right;
        color: var(--main-color);
        cursor: pointer;
        padding-left: 1rem;
        text-decoration: underline;
      }
    }
  }

  &__table {
    text-align: center;
    width: 100%;
    background: #FFFFFF;

    &-event-text {
      text-align: left;
    }

    &-wrapper {
      height: 100%;
      box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
      overflow-x: auto;
      @include custom-scroll();
    }

    thead {
      background: #3B4A5D;

      th {
        border-bottom: 1px solid #DAE3EE;
      }
    }

    th, td {
      vertical-align: middle;
      padding: .3rem 10px;
      min-width: 50px;
    }

    &-head-text, thead th {
      font-weight: $font-bold;
      line-height: 24px;
      color: white;
    }
  }
}

.filters-sidebar {
  background: #E9E9E9;
  position: relative;

  &-toggle {
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 10px;
    z-index: 10;
    text-align: center;

    @include mobile {
      right: 13px;
    }
  }
}

.filters {
  max-width: 300px;
  padding: 1rem 1rem 0 1rem;

  &-form {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: .5rem;

    & input {
      &:not([type="checkbox"]) {
        height: 35px;
      }
      width: 100%;
      border-radius: 5px;
    }
  }

  &-search, &-date {
    display: flex;
    gap: .5rem;

    & input {
      padding: 0 12px;
      border: 1px solid var(--light_grey);
    }

    & :focus {
      color: #212529;
      background-color: #fff;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
    }
  }

  &-button {
    display: flex;
    gap: .5rem;
    height: 40px;

    &-apply {
      @include main-button();
      width: 60%;
    }

    &-quote {
      @include main-button();
      border: 1px solid var(--main-color);
      width: 100px;
    }

    &-reset {
      @include main-button();
      width: 40%;
      border: 1px solid var(--grey);
      background: #9A9A9A;

      &:disabled {
        background: #9A9A9A;
        color: white;
      }
    }
  }

  &-accordion {

    &-header {

      & button {
        font-weight: bold;
        color: #173454;
      }
    }

    &-body {
      padding: 0;
    }

  }

  &-expand {
    width: 300px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include mobile {
      width: calc(100vw - 40px);
    }
  }

  &-collapse {
    width: 57px;

    @include mobile {
      width: 40px;
    }
  }

  .accordion-button {
    font-family: $body-font;
    background: transparent;
    font-weight: $font-bold;
    font-size: $large-font-size;
    padding: 0;
    border: none;

    &:not(.collapsed) {
      color: #173454;
      background-color: transparent;
      box-shadow: none;
    }

    &:focus {
      background-color: transparent;
      box-shadow: none;
    }

    &:after {
      margin-left: 12px;
      background: center / contain url("../../../assets/images/sell-page/AccordionArrow.svg") no-repeat;
      width: 10px;
      height: 8px;
    }
  }

  .accordion-item {
    background: transparent;
    border: none;
  }
}

@include tablet-1024 {
  .user-sell-page {
    min-width: 100%;
    z-index: 2;
    margin: 30px 0 0 60px;
  }

  .filters-sidebar {
    position: fixed;
    z-index: 3;
    background: #E9E9E9;
    height: 100vh;
  }
}

@include tablet-744 {
  .user-sell-page {
    overflow: auto;

    &__table-block {
      max-width: unset;
      width: 1450px;
    }

    &__table-block-upload-file {
      font-family: $body-font;
      font-size: $large-font-size;
      justify-content: flex-start;
    }
  }
}

@include small-layout {
  .filters {
    max-width: unset;
  }

  .react-datepicker-popper {
    z-index: 3 !important;
  }
  .user-sell-page {
    width: 100%;
    margin: 0;

    &__table {
      &-head-text {
        position: sticky;
        top: 0;
        z-index: 2;
      }
    }

    &__header {
      border-radius: 0;
    }

    &__top-block {
      max-width: 358px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0 16px;

      &-title {
        color: var(--blue, #173454);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &-sub-title {
        color: var(--black, #333);
        line-height: 24px;
      }

      p {
        margin: 0;
      }
    }

    &__table-block {
      max-width: 100%;

      &-upload-file {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 16px 24px;

        span {
          padding: 0;
          color: var(--red, #9A1032);
          line-height: normal;
          text-decoration-line: underline;
        }
      }
    }
  }

  .filters-sidebar {
    display: none;
  }

  .matches {
    display: none;
  }
}
