.flex-row {
  display: flex;
  gap:8px;
  flex-wrap: wrap;
  row-gap: 8px;
}

.input-width {
  max-width: 345px;
  width: 100%;
}

.date-inputs-container {
  width: 100%;
  display: flex;
  gap:8px;

  > * {
    max-width: 345px;
    width: 100%;
  }
}

.purchased-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;

  h1 {
    margin: 0
  }
}
