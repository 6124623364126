@import "src/assets/styles/variables";

.quote-single {
  border-bottom: 1px solid var(--main-color);
  margin: 15px 0 15px 0;
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.quote-container {
  display: flex;
  justify-content: space-between;

  &_info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-pricing {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      gap: 10px;

      & p {
        margin: 0;
      }
    }

    & p {
      margin: 0;
    }
  }

  &_pricing {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
    }

    &-select {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & p {
        margin: 0;
      }

      & select {
        border: 1px solid #ced4da;
        height: 40px;
        border-radius: 5px;
        width: 100%;
      }
    }
  }

  &_commission {
    display: flex;
    justify-content: space-between;
    text-align: center;

    & p {
      margin: 0;
      padding: 0 0 8px 0;
    }

    &-input-container {
      display: flex;
      gap: 10px;

      & input {
        border: 1px solid var(--main-color);
        border-radius: 8px;
        outline: none;

        &:focus {
          outline: none
        }
      }

      & button {
        width: 25px;
      }
    }

    &-btns {
      display: flex;
      gap: 10px;
    }
  }
}

.pricing-btn {
  width: 60px;
  height: 30px;
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--main-color);

  &:hover {
    background-color: var(--main-color);
    color: white;
  }

  &_generate {
    width: 100%;
    height: 100%;
    margin: 10px 0;

    &:hover {
      color: white;
    }
  }

  &_down {
    background-image: url('../../../../../assets/images/other/arrowDownDark.svg');
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-image: url('../../../../../assets/images/other/arrowDownWhite.svg');
    }
  }

  &_up {
    background-image: url('../../../../../assets/images/other/arrowUpDark.svg');
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-image: url('../../../../../assets/images/other/arrowUpWhite.svg');
    }
  }

  &_math {
    &:hover {
      color: white;
    }
  }
}

.active-btn {
  color: white;
  background-color: var(--main-color);
}

.commission-input {
  width: 70px;
  text-align: center;
}

.quantity-changer-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;

  &_header {
    margin: 0;
    width: 100%;
    text-align: right;

  }

  &_buttons {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 8px;
  }
}

.btn-height {
  height: 25px;
}

textarea {
  border: 1px solid var(--main-color);
  border-radius: 8px;
  padding: 5px;

  &:focus {
    outline: none;
  }
}

.commission {
  &__input {
    display: none;
  }

  &__input:checked + label {
    background-color: $red;
    color: white;
  }
}
