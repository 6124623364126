.month-container {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #AAABBE;
  position: sticky;
  top: 0;
  background: #F3F7FA;
}

.month-title {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.calendar-items-container {
  padding: 0 20px 0 0;
  margin-top: 21px;
  display: flex;
  flex-direction: column;
  gap: 64px;
  overflow: auto;
  max-height: calc(100vh - 192px);
}

