.settings {
  margin: 24px 0 0 24px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 20px;

  label{
    padding-top: 10px;
    padding-bottom: 4px;
  }

  &__social {
    display: flex;
    flex-direction: column;

    &__logo {
      border-radius: 50%;
      width: 7.5rem;
      height: 7.5rem;
    }
  }

  .auth-wrapper__submit-btn {
    margin-top: 20px;;
  }

}

.settings-tab-width {
  width: 100%;

  &-api-keys {
    width: 100%
  }
}

.settings .nav-tabs .nav-link {
  background: var(--white);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--main-color);
}

.settings .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: var(--main-color);
  color: var(--white);
}
.settings .nav-tabs .nav-item button:disabled {
  background: var(--grey2);
  color: var(--main-color);
}


.setting__checkbox {
  &-wrapper {
    display: flex;
    align-items: center;
    padding: .5rem 0;
  }

  &-label {
    padding-top: 0!important;
    padding-bottom: 0!important;
    padding-left: 5px;
    cursor: pointer;
  }

  cursor: pointer;
}

.setting__select {
  &-wrapper {
    display: flex;
    padding-inline: 0 !important;
    width: 100% !important;

    .select-label {
      padding-top: 0!important;
      padding-bottom: 0!important;
    }

    select {
      width: 60%!important;
    }
  }
}

.balance {
  display: flex;
  justify-content: space-between;
  margin: .5rem 0;

  &__info {
    display: flex;
    gap: 10px;

    &-text {
      margin: 0;
    }
  }

  &__button {
    border: 0;
    background: var(--main-color);
    border-radius: 5px;
    color: #fff;
    height: 30px;
    width: 100px;
  }
}

.domain {
  display: flex;
  align-items: center;
  position: relative;

  p {
    padding: .375rem .75rem;
    border: 1px solid #94A6BD;
    border-radius: .25rem;
    line-height: 1.6;
    background: #eceaea;
  }

  &__protocol {
    border-right: none!important;
    border-radius: .25rem 0 0 .25rem!important;
  }

  .default-formik-input{
    width: 100%;
    .subdomain-input {
      border-radius: 0!important;
      text-align: end;
    }
  }

  &__main-domain {
    border-radius: 0 .25rem .25rem 0!important;
    border-left: none!important;
  }

  &__copy-domain-button {
    padding: 0 0.75rem;
    cursor: pointer;
    margin-left: 10px;

    border: 0;
    background: var(--main-color);
    border-radius: 5px;
    color: #fff;
    height: 30px;
    width: 182px;

    &:disabled {
      background: var(--grey2);
      color: var(--main-color);
    }
  }

  .copy-success {
    color: green;
    position: absolute;
    right: 0;
    bottom: -28px;
  }

}
