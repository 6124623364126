@import "src/assets/styles/variables";

.registration-form {
  width: 100%;
  padding: 10px 0;

  &__form {
    display: flex;
    flex-direction: column;
    gap: .5rem;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #173454;
  }

  &__sub-title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #173454;
  }

  &__row-under-form {
    margin-top: 15px;
    width: $mobile-width;

    p {
      line-height: 16px;
      color: #9A9A9A;
    }
  }
}
