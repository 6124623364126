:root {
  --main-color: #9A1032;
  --main-title-color: #173454;
  --main-text-color: #333333;
  --header-heght: 70px;
  --scroll-color: #b6b6b6;
  --scroll-color-background: #fff;
  --scroll-size: 12px;

  --landing-title-color: #375A7E;
  --landing-welcome-block-info-margin-left: 105px;

  --sell-page-filter-max-size: 450px;

  //redesign color
  --gold: #B0870B;
  --blue: #173454;
  --blue2: #DDE5EC;
  --dark_blue: #3B4A5D;
  --white: #FFFFFF;
  --black: #333333;
  --grey: #9A9A9A;
  --grey2: #E9E9E9;
  --red: #9A1032;
  --light_grey: #ced4da;
}

$mobile-width: 430px;

// COLORS
$white: #FFFFFF;
$red: #9A1032;
$gray: #A0ADBA;
$light-gray: #E8EDED;
$inputs-grey: #CED4DA;
$table-grey: #DAE3EE;
$column-blue: #F0F2F5;
$row-blue: #DDE5EC;
$blue: #173454;
$black: #333333;
$light-grey: #7D7E8E;
$navigationGray: #3b4a5d;
$error-red: #dc3545;

//TEXT
$table-font: "Roboto", sans-serif;
$body-font: "Montserrat", sans-serif;
$larger-font-size: 1.5rem;
$large-font-size: 1rem;
$semi-large-font-size: 1.125rem;
$normal-font-size: .813rem;
$small-font-size: .625rem;

$font-normal: 400;
$font-semi-bold: 600;
$font-bold: 700;

//Input
$default-input-height: 35px;
$default-input-border-radius: 0.25rem;
