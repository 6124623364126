.page-loading {
  position: fixed;
  height: 100vh;
  width: 100%;

  &__loader-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 100px;
  }

  &__loader-box {
    margin: 0 auto;
    width: 40px;
    height: 160px;
    position: relative;

    &-shadow {
      position: absolute;
      width: 60px;
      height: 10px;
      background-color: grey;
      bottom: 0;
      border-radius: 100%;
      transform: scaleX(.8);
      opacity: .6;
      animation: shadowScale 1s linear infinite;
    }
  }

  &__loader-gravity {
    width: 60px;
    height: 60px;
    animation: bounce 1s cubic-bezier(0.68, 0.35, 0.29, 0.54) infinite;
  }

  &__loader-ball {
    width: 60px;
    height: 60px;
    background-image: url('https://cdn2.iconfinder.com/data/icons/activity-5/50/26BD-soccer-ball-128.png');
    background-size: cover;
    animation: roll 1s linear infinite;
  }

  @keyframes roll {
    0% {
    }
    100% {
      transform: rotate(360deg)
    }
  }

  @keyframes bounce {
    0% {
    }
    50% {
      transform: translateY(100px)
    }
    100% {
    }
  }

  @keyframes shadowScale {
    0% {
    }
    50% {
      transform: scaleX(1);
      opacity: .8;
    }
    100% {
    }
  }
}
