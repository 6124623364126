@import "src/assets/styles/variables";

.link-container {
  padding: .5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  p {
    font-weight: $font-normal;
    font-size: $normal-font-size;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 1.5rem;

  }

  &__buttons-item {
    width: 100%;

    a {
      font-size: $normal-font-size !important;
    }
  }
}

.app-select-container {
  padding: .5rem 2rem;
  width: 100%;
}

.select-items {
  display: flex;
  gap: .5rem;

  &__container {
    display: flex;
    gap: .5rem;

    &:first-child {
      flex-basis: 50%;
    }

    &:last-child {
      flex-direction: column;
    }

    &-item {
      display: flex;
      gap: .5rem;
      justify-content: space-between;
    }
  }
}

.order-form-buttons {
  width: 100%;
  display: flex;
  padding: 0 2rem 2rem;
  gap: 1rem;
}
