.invoice-form-viagogo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &_container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  select {
    display: block;
    background-color: var(--white);
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    width: 100%;
  }

  input {
    width: 100% !important;
  }
}

.form-viagogo-inputs_item {
  width: 100%;
}
