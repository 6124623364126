@import "../../../assets/styles/variables";

.main-info-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 1rem;
  border-bottom: 2px solid $inputs-grey;
  margin-bottom: 2rem;

  p {
    margin: 0;
    font-size: $large-font-size;
    font-weight: $font-semi-bold;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
}

.hold-inventory-info {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &__text-container {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    text-align: left;

    p {
      flex-basis: 25%;
    }
  }

  &__container {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    &-header {
      display: flex;
      justify-content: space-between;
      gap: .5rem;
      background-color: $row-blue;
      text-align: left;

      p {
        flex-basis: 25%;
      }
    }

    &-text {
      font-size: $normal-font-size;
      line-height: 20px;
      font-weight: $font-normal;
    }
  }
}

.hold-inventory-form {
  display: flex;
  flex-direction: column;

  &__header {
    margin-bottom: 36px;
    border-bottom: 1px solid $inputs-grey;

    p {
      color: $blue;
      margin: 0;
      font-size: $semi-large-font-size;
      line-height: 28px;
      font-weight: $font-bold;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: .5rem;

    &-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      padding-top: 1rem;
      border-top: 1px thin $inputs-grey;
      margin-top: 32px;
    }

    &-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      textarea {
        resize: none;
        border-color: $inputs-grey;
      }
    }

    select {
      width: 100%;
      height: 100%;
      min-width: 0;
      border: 1px solid $inputs-grey;
      border-radius: 5px;
    }
  }
}

.customer-button {
  align-self: flex-end;
}

.custom-hold-basis {
  flex-basis: 50% !important;
}

.hold-error-container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 32px;
  padding: 1rem;
  border: 1px solid $red;
  border-radius: 5px;

  p {
    font-size: $large-font-size;
    font-weight: $font-semi-bold;
    line-height: 20px;
    color: rgba(154, 16, 50, 1);
  }
}
