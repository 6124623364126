@import "../../../assets/styles/variables";

.inventory-data {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  margin: 30px;
  gap: 16px;

  &-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.data-text {
  max-width: 170px;
  width: 100%;

  h6 {
    font-size: $normal-font-size;
    font-family: $body-font;
    font-weight: $font-bold;
  }
}

.change-po-form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 100%;

  h5 {
    font-size: $large-font-size;
    font-family: $body-font;
    font-weight: $font-bold;
  }
}

.edit-fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.buttons-container {
  display: flex;
  gap: 10px;
}

.button-gray {
  border: none;
  background-color: gray;
}
