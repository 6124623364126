@import "../../../../../assets/styles/variables.scss";
@import "../../../../../assets/styles/media.scss";
@import "../../../../../assets/styles/mixins.scss";

.event-info {
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;

  &_header {
    text-align: center;
    margin: 0 0 20px 0;

    & h5 {
      margin: 0;
    }
  }

  &_block {
    display: flex;
    gap: 8px;
    justify-content: space-between;

    &-text {
      margin: 0;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buy-inventory {
  &__buttons {
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
  }

  &__buttons-item {
    width: 100%;
  }

  &-modal {
    padding: 0;
  }

  &__info {
    display: flex;
    padding: 1.5rem;
    gap: 2rem;
    justify-content: space-between;
    background-color: $blue;
  }

  &__event {
    display: flex;
    flex-direction: column;
    width: 100%;

    p {
      color: #fff;
      font-size: $large-font-size;
    }
  }

  &__event-time {
    border-left: thin solid $white;
    text-align: right;

    p {
      color: $white;
    }
  }

  &-header {
    font-size: $larger-font-size;
    padding: 0 10px;
  }

  &-container {
    padding: 1rem 1.5rem 2rem 1.5rem;

    &:last-child {
      padding: 0 1.5rem;
    }

    &__text {
      text-align: right;
    }
  }

  &__listing-header {
    display: flex;
    justify-content: space-between;
    background-color: $gray;

    p {
      flex-basis: 33.33%;
      padding: .5rem 1rem;
      color: $white;
    }

    &_second {
      p {
        &:first-child {
          background-color: $white;
        }
      }
    }
  }

  &__listing-data {
    display: flex;
    justify-content: space-between;
    background-color: $inputs-grey;

    p {
      flex-basis: 33.33%;
      padding: .5rem 1rem;
    }

    &_second {
      p {
        &:first-child {
          background-color: $white;
        }
      }
    }
  }

  &__listing-info {
    display: flex;

    p {
      padding: 0.5rem 1rem;
      line-height: 1.25rem;

      &:first-child {
        padding: .5rem 1rem .5rem;
        max-width: 151px;
        width: 100%;
      }
    }

    &_gray {
      background-color: $inputs-grey;
    }
  }

  &-form {
    padding: 0 1.5rem;

    &__block {
      display: flex;

      p {
        max-width: 151px;
        padding: .5rem 1rem .5rem;
        width: 100%;
      }

      select {
        max-width: unset;
        border: none;
        width: 100%;
        margin: 0 0 0 1rem;

        &:hover {
          cursor: pointer;
          border: thin solid $inputs-grey;
          border-radius: 5px;
        }
      }
    }
  }
}

@include small-layout {
  .buy-inventory {
    &__info {
      flex-direction: column;
      gap: unset;
    }

    &__event {
      p {
        &:last-child {
          padding-bottom: 1rem;
        }
      }
    }


    &__event-time {
      text-align: left;
      border: none;
      border-top: thin solid $white;

      p {
        padding-top: 1rem;
      }
    }

    &__buttons {
      flex-direction: column;
    }
  }
}
