@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
@import "src/assets/styles/media";

.main-interface-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;

  &__user-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__toggle {
    max-width: 240px;
    width: 100%;
    color: #FFFFFF;
    padding: 8px 22px 8px 24px;
    border: 1.5px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px;
  }

  &__toggle.active {
    background: #A32846FF;
  }

  &__toggle-menu {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      display: block;
      text-decoration: none;
      color: #173454;
      padding: 1rem .75rem;
      width: 240px;

      &:hover {
        background: #F3F7FA;
      }
    }
  }

  &__toggle-menu-icon {
    width: 24px;
    height: 24px;
    margin: 0 15px 0 0;
  }

  &__toggle-menu-chevron-bottom-icon {
    margin-left: 15px;
  }
}

.user-navbar {
  display: flex;
  gap: 1rem;
  align-items: center;
  max-width: 696px;
  width: 100%;
  justify-content: space-between;

  span {
    padding: 0 .5rem;
  }

  #basic-nav-dropdown {
    color: #FFFFFF;
  }

  .dropdown-toggle.nav-link {
    text-align: center;
    padding: 0 .5rem;
  }

  #basic-nav-dropdown.dropdown-toggle.nav-link[aria-expanded="true"] {
    font-weight: $font-bold;
    color: $red;
  }

  &__link {
    font-size: $large-font-size;
    color: #fff;
    line-height: 30px;
    text-decoration: none;
    font-weight: $font-normal;
    font-family: $body-font;

    &:hover {
      color: #fff;
    }
  }
}

.dropdown-menu a {
  color: #000;
  font-size: $font-bold;
  padding: 9px 24px;
}

.dropdown-menu[data-bs-popper] {
  margin-top: 21px;
}

.dropdown-menu a:hover {
  background-color: rgba(233, 233, 233, 1);
}

.dropdown-menu a:active {
  background-color: $red;
}

.header-wrapper.header-opened-white .main-interface-header__user-name {
  color: $red;
}

.header-wrapper.header-opened-white .main-interface-header__toggle-menu-chevron-bottom-icon {
  filter: contrast(0.5) brightness(0.5);
}

.header-wrapper.header-opened-white #basic-nav-dropdown {
  color: $red;
  font-weight: $font-normal;
}

.header-wrapper.header-opened-white .user-navbar__link {
  color: $red;
}

.header-wrapper.header-opened-white .main-interface-header__toggle {
  border-color: $red;
  background-color: $white;
  transition: background-color 0.75s ease-in-out;
}

.bell {
  background-image: url("../../../assets/images/other/bell.svg");
  height: 1.5rem;
  width: 1.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.header-wrapper.header-opened-white .bell {
  background-color: transparent;
  background-image: url("../../../assets/images/other/bellRed.svg");
}

@include tablet-1024 {
  .main-interface-header {
    .navbar {
      padding: 0;
    }

    .container {
      padding: 0;
    }

    .navbar-toggler {
      border: none;
      margin: 0;
      padding: 0;

      span {
        background-image: url("../../../assets/images/other/burgerWhite.svg");
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
}
