@import "../../../../../../assets/styles/variables";

.form-inputs .default-formik-input {
  flex-basis: 50%;
}

.form-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  text-align: center;

  & select {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    border-radius: 5px;
    flex-basis: 50%;
    max-width: 233px;
    background-color: $white;
  }

  button {
    margin: 15px 0;
    border: 1px solid var(--main-color);
  }

  &_item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start;

    &__field-error {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-header {
      margin: 0;
      flex-basis: 50%;
      text-align: left;
    }
  }
}

.wanted-inventory-form {
  padding: 1rem 1.5rem;

  p {
    text-align: right;
  }

  select {
    &:hover {
      cursor: pointer;
    }
  }
}

.wanted-inventory-error {
  position: absolute;
  top: 0;
  right: 20px;
  color: red;
}

.drop-padding {
  padding: 0 1rem !important;
}
