@import "src/assets/styles/variables";

.calendar-item-container {
}

.calendar-blur {
  filter: blur(2px);
}

.calendar-item-wrapper {
  position: relative;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
}

.combination-info {
  display: flex;
  flex-direction: column;

  &-title {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0;
  }
}

.event-name {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.calendar-body {
  margin-top: 36px
}


.stick-header-calendar-dates {
}

.calendar-body {
  table {
    tbody {
      border-top: 1.5px solid #E9E9E9 !important;

      td {
        padding: 0;
        width: 15%
      }
    }
  }
}

.empty-calendar-modal {
  width: 500px;
  padding: 32px 43.5px;
  border-radius: 8px;
  position: absolute;
  top: 40%;
  left: 30%;
  background: white;
  z-index: 2;


  &-body {
    display: flex;
    gap: 24px;
  }

  &-content {
    font-family: $table-font;
    font-weight: $font-normal;
    font-size: $normal-font-size;
    line-height: 20px;
  }

  &-close {
    display: flex;
    justify-content: end;

    img {
      margin: 0;
    }
  }
}

@media (max-width: 744px) {
  .empty-calendar-modal {
    width: 300px;
  }
}

