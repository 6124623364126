@import "../../../assets/styles/media.scss";

.main-interface {
  height: calc(100vh - var(--header-heght));
  width: 100%;
  display: flex;

  &__content {
    width: 100%;
    display: flex;
    gap: 1rem;

    @include mobile {
      width: 100%;
    }
  }
}

@include small-layout {
  .main-interface {
    &__content {
      width: 100%;
    }
  }
}
