.event-name-td {
  max-width: 300px;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-border-td {
  border: none  !important;
}

.sold-inventory-modal {
  min-width:80vw;
}
