@import "../../../assets/styles/media.scss";

.stick-header-events-table {
  position: sticky !important;
  top: 0;
  background-color: var(--white);
  z-index: 1;
}

.ac-milan-events {
  height: calc(100vh - 540px) !important
}

.events-page {
  background: #FFFFFF;
  box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
  border-radius: 5px;
  position: relative;
  overflow-y: hidden;

  &__filters {
    position: sticky;
    width: 100%;
    background: var(--white);
    z-index: 2;
    padding: .5rem;

    &__wrapper {
      display: flex;
      flex-direction: row;
      gap: .5rem;
    }

    &__wrapper .default-formik-input {
      width: 100%;
    }

    &__component {
      display: flex;
      justify-content: space-between;
      width: 100%;

      select {
        display: block;
        background-color: var(--white);
        border: 1px solid #ced4da;
        padding: 0.375rem 0.75rem;
        height: 35px;
        border-radius: 5px;
        width: 100%;
      }

      input {
        border: 1px solid #ced4da;
        padding: 0.375rem 0.75rem;
        &[type="checkbox"] {
          padding: 0.375rem;
        }
      }

      label {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      input[type=checkbox] {
        width: auto;
        margin-right: 5px;
      }
    }

    &__button {
      width: 100%;
      height: 35px;
    }
  }

  &__filters-fixed {
    top: 90px;
  }

  &__table-padding {
    padding-top: 182px;
  }
}

@include small-layout {
  .events-page {
    padding: 0;

    &__table-padding {
      padding: 0;
    }
  }
}
