@import "src/assets/styles/mixins";
@import "src/assets/styles/media.scss";

.needs-list-link {
  text-decoration: none;
  color: $black;
  background-color: $white;
  padding: 10px 12px;
  text-align: center;
  height: 43px;
  font-weight: $font-normal;
  font-size: $semi-large-font-size;
  line-height: 22px;
  border-radius: 5px;

  &:hover {
    color: $black;
  }
}

.landing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &__navbar-left-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &__navbar-links {
    text-decoration: none !important;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;

    &:hover {
      color: #d2d2d2;
    }
  }

  &__navbar-login-button {
    height: 43px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    margin-left: 70px;
    background: #FFFFFF;
    border-radius: 5px;

    a {
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: var(--main-text-color);
    }
  }
}

.header-notification {
  position: relative;
  display: flex;
  gap: 8px;
  align-items: center;
}

.header-logo-wrapper {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.notification-modal {
  width: 600px;
  padding: 12px;
  background: var(--broker-grey-2, #E9E9E9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  position: absolute;
  left: 0;
  top: 60px;

  &__title-container {
    display: flex;
    width: 100%;
    padding: 14px 24px;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    background: var(--broker-red, #9A1032);
  }

  &__title {
    color: var(--broker-white, #FFF);
    font-family: "Century Gothic", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px; /* 141.667% */
    margin: 0;
  }

  &__content {
    width: 100%;
    display: flex;
    padding: 28px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: var(--broker-white, #FFF);

    &__item {
      display: flex;
      width: 100%;
      padding-bottom: 6px;
      align-items: flex-start;
      gap: 12px;
      border-bottom: 1px solid var(--broker-grey-2, #E9E9E9);

      a {
        text-decoration: none;
        width: 100%;
        text-align: left;
        margin: 0;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        line-height: 22px; /* 157.143% */
      }

      p {
        width: 100%;
        text-align: left;
        margin: 0;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        line-height: 22px; /* 157.143% */
      }
    }

    &__item-text-date {
      color: var(--Base-Black, #262626);
      font-weight: 400;

    }

    &__item-text-title {
      color: var(--broker-blue, #173454);
      font-weight: 600;
    }
  }
}

.pointer {
  cursor: pointer;
}

.fixed-width {
  &_date {
    max-width: 110px;
    width: 100%;
  }

  &_lang {
    max-width: 46px;
    width: 100%;
  }
}

@include tablet-1024 {
  .notification-modal {
    width: 554px;
    gap: 16px;
  }

  .fixed-width {
    &_date {
      width: 95px;
    }
  }
}

@media (max-width: 840px) {
  .header-notification {
    position: unset;
  }

  .notification-modal {
    top: 70px;
    left: 0;
  }

  .landing-header {
    position: relative
  }
}

@include tablet-744 {
  .notification-modal {
    width: 540px;
  }

  .fixed-width {
    &_date {
      width: 90px;
    }
  }
}

@media (max-width: 600px) {
  .notification-modal {
    width: 450px;
    gap: 16px;
    left: -80px;
  }
}


@include small-layout {
  .header {
    &__navbar {
      margin: 0 16px;
    }
  }
  .landing-header {

    &__navbar-login-button {
      color: var(--black, #333);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    &__navbar-left-block {
      gap: 16px;
    }

    &__navbar-links {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0;
    }

    button {
      margin: 0;
    }
  }

  .notification-modal {
    width: 406px;
    gap: 16px;
    left: -15px;

    &__content {
      &__item {
        p {
          font-size: 14px;
        }
      }
    }
  }

  .fixed-width {
    &_date {
      width: 73px;
    }

    &_lang {
      width: 18px;
    }
  }

}

@include small-layout {
  .header-notification {
    gap: 8px;
  }

  .header-logo-wrapper {
    gap: 24px;
  }
}

