.stubhub {
  &__input {
    max-width: 250px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}
