@import "../../../assets/styles/variables";

select {
  height: $default-input-height;
}

.form-select {
  height: 35px;
  font-size: $normal-font-size;
}

.app-select {
  width: 100%;
  position: relative;
  cursor: pointer;
  height: $default-input-height;

  ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f0f0f0;
  }

  ul::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f0f0f0;
  }

  ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #ccc;
  }

  &__header {
    border: thin solid $inputs-grey;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    white-space: nowrap;
  }

  &__header-arrow {
    padding: 0 1rem;
  }

  &__header-arrow-up img {
    padding: 0 1rem;
    transform: rotate(180deg);
  }

  &__header-name {
    padding-left: 1rem;
  }

  .hidden {
    display: none;
  }

  &__body {
    &-placeholder {
      white-space: nowrap;
      color: $gray;
    }
  }

  ul {
    position: absolute;
    z-index: 3;
    background-color: $white;
    border: thin solid $inputs-grey;
    border-radius: 5px;
    width: 100%;
    padding: .5rem;
    max-height: 250px;
    overflow-y: auto;

    li {
      padding: 0 0.5rem;
      margin-bottom: 0.125rem;
      border-radius: 3px;
      &.selected {
        background-color: $light-gray;
      }
      &:hover {
        background-color: $table-grey;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
