@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";

.auth-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;

  &__top-points {
    position: fixed;
    width: 198.33px;
    left: 10px;
    top: 10px;
    z-index: -1;
  }

  &__bottom-points {
    position: fixed;
    left: 10px;
    bottom: 0;
    display: flex;
    align-items: center;
    z-index: -1;
  }


  &__form {
    display: flex;
    align-items: center;
    z-index: 0;
    margin-left: 10rem;
    max-width: $mobile-width;
    width: 100%;

    @media (max-width: 1300px) {
      margin-left: 0;
      margin: 0 auto;
      padding: 0 10px;
    }
  }

  &__left-block {
    position: relative;
    z-index: 1;

    @media (max-width: 1300px) {
      width: 100%;
    }
  }
  
  /* Right Block Start */
  &__right-block {
    position: relative;
    z-index: 0;

    @media (max-width: 1300px) {
      display: none;
    }
  }

  &__red-rectangle {
    position: relative;
    display: flex;
    align-items: center;
    height: 100vh;
    & >img{
      height: 100vh;
      width: 100%;
    }
  }

  &__ellipses {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__left-message-in-rectangle {
    position: absolute;
    left: 30%;
    top: 33%;

    .auth-wrapper__b2b-exchange-title {
      font-style: normal;
      font-weight: 700;
      font-size: 60px;
      line-height: 78px;
      color: #FFFFFF;
    }

    .auth-wrapper__b2b-exchange-message {
      width: 352px;
      line-height: 30px;
      color: #FFFFFF;
    }
  }

  &__submit-btn {
    @include main-button();
    width: 100%;
  }

  &__input {
    @include main-input();
    width: 100%;
  }
}
