@import "src/assets/styles/variables";

.app-smart-search {
  position: relative;
  width: 100%;
}

.app-smart-search-list {
  position: absolute;
  z-index: 2;
  list-style-type: none;
  padding: 0;
  width: 100%;
  border-radius: 0.25rem;
  border: thin solid $inputs-grey;
  max-height: 400px;
  overflow: auto;

  &__item {
    position: relative;
    padding: 6px 12px;
    background: #fff;

    &:not(:last-child) {
      border-bottom: thin solid $inputs-grey;
    }

    &:hover {
      color: #fff;
      background-color: $red;
    }
  }
}

.lens-icon {
  width: 20px;
  position: absolute;
  top: 8px;
  right: 10px;
  z-index: 2;
  height: 20px;
  background-image: url('../../../assets/images/other/lens.svg');
}
