.events-page__filters__wrapper {
  .narrow-input {
    width: 120px;
    flex-shrink: 0;
  }

  .filters-button-reset {
    max-width: 60px;
  }
  .filters-button-apply {
    max-width: 90px;
  }

  .app-date-picker {
    width: 100%;
  }
}

.categories-table {
  justify-content: center;
}
