@import "../../../../../../assets/styles/variables.scss";

.unauthorized-fields {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.needed-inventory {
  &__modal {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    p {
      &:last-child {
        font-weight: $font-semi-bold;
      }
    }
  }

  &__form {
    display: flex;
    flex-direction: column;

    textarea {
      width: 100%;
      resize: none;
      min-height: 100px;
    }

    button {
      margin-top: 2rem;
      width: 25%;
      align-self: center;
    }
  }

  &__header-row {
    &:first-child {
      p {
        flex-basis: 25%;
        font-weight: $font-semi-bold;
        font-size: $large-font-size;
      }
    }

    display: flex;
    justify-content: space-between;
    gap: 1rem;

    p {
      flex-basis: 25%;
    }
  }
}
