@import "../../../assets/styles/variables.scss";

.purchased-inventories-modal {
  min-width: 80vw;
}

.clickable-td {
  color: $red;

  &:hover{
    cursor: pointer;
  }
}
