.stripe {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__input {
    width: 200px;
    margin: 0 auto;
    text-align: right;
  }
}

.payment {
  border: 0;
  background: var(--main-color);
  border-radius: 5px;
  color: #fff;
  height: 30px;
  width: 100px;
  margin: 0 auto;

  &__methods {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 150px;
  }
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &_text {
    margin: 0;
  }

  p {
    margin: 0;
  }

}
