@import "../../../assets/styles/media.scss";

.currency-container {
  display: flex;
  gap: 10px;
  align-items: center;

  p {
    margin: 0;
  }

  select {
    color: #000;
  }
}

.buy-page {
  &-overlay {
    position: fixed;
    top: 70px;
    left: 75px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 7;
    display: block;
  }

  &-tabs {
    margin: 0;
    padding-top: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--header-heght));
  }

  &-container {
    display: flex;
    align-items: start;

    &_mobile {
      flex-direction: column;
    }
  }

  &-tabs .nav-tabs {
    height: 42px;
  }

  &-tabs .tab-content {
    height: calc(100% - 42px);
  }

  &-tabs .tab-pane {
    height: 100%;
  }

  &-tabs .buy-page-container {
    height: 100%;
  }

  &-tabs .find-events-sidebar {
    height: 100%;
  }
}

.buy-filters-sidebar {
  background: #E9E9E9;
  position: relative;

  &-toggle {
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: 20px;
    top: 30px;
    z-index: 10;
    text-align: center;
  }
}

.wanted-form {
  margin: 30px 0 0 0;
  max-width: 250px;
  width: 100%;
  background: #E9E9E9;
  padding: 10px;
}

.trade-button-container {
  width: 100px;
  display: flex;
  gap: 5px;
  margin: 0 auto;
}

.buy-button-container {
  width: 100px;
  margin: 0 auto;
}

.nav-tabs .nav-link {
  background: var(--white);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--main-color);
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background: var(--main-color);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--white);
}

@include tablet-1024 {
  .page-tabs-margin {
    margin: 0 0 0 60px;
  }

  .buy-page-table-margin {
    margin: 0 0 0 57px;
  }

  .buy-filters-sidebar {
    position: absolute;
    z-index: 3;
    background: #E9E9E9;
  }
}

@media (max-width: 810px) {
  .buy-page-table-margin {
    margin: 0 0 0 40px;
  }

  .page-tabs-margin {
    margin: 0 0 0 40px;
  }

  .buy-page-overlay {
    left: 35px;
  }
}

@include small-layout {
  .buy-filters-sidebar {
    display: none;
  }

  .buy-page-overlay {
    display: none;
  }

  .buy-page-table-margin {
    margin: 0 0 0 0;
  }
}


.ticket-groups {
  display: flex;
  gap: 8px;
  max-width: 288px;
  width: 100%;

  &-button {
    max-width: 140px;
    width: 100%;
    border-radius: 5px;
    background-color: #9A9A9A;
    color: #fff;

    &_active {
      max-width: 140px;
      width: 100%;
      border-radius: 5px;
      background-color: var(--main-color);
      color: white;
    }
  }
}


.event-groups {
  display: flex;
  gap: 8px;
  max-width: 208px;
  width: 100%;

  & button {
    max-width: 100px;

  }
}


@include small-layout {
  .event-groups {
    margin: 15px 0 0 0;
  }
}
