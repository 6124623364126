@import "src/assets/styles/variables";

.login-form {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  input {
    margin-bottom: .5rem;
  }

  &__welcome-title {
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #173454;
  }

  &__sub-welcome-title {
    font-size: 18px;
    line-height: 30px;
    color: #173454;
  }

  .row-under-form {
    margin-top: 15px;
    width: $mobile-width;

    p {
      line-height: 16px;
      color: #173454;
    }
  }

  &__auth-actions-block {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
