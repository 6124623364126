@import "../../../assets/styles/variables";

.app-paginated-table {
  height: 100%;
  background: #FFFFFF;
  box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
  border-radius: 5px;
  overflow-x: auto;
  white-space: nowrap;
  min-height: 300px;

  td:first-child {
    position: relative;
  }

  &_td-id {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;

    &__tooltip {
      visibility: hidden;
      background-color: var(--main-color);
      color: var(--white);
      text-align: center;
      border-radius: 5px;
      padding: 0 5px;
      position: absolute;
      left: 0;
      z-index: 1;
    }

    &:hover &__tooltip {
      visibility: visible;
    }
  }

  &-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  table {
    border-collapse: separate;
  }

  .table > :not(caption) > * > td {
    border-bottom-width: 0;
  }

  thead {
    box-shadow: 0 -25px 0 0 white;
    z-index: 1;
    position: relative;

    tr {
      position: sticky;
    }
  }

  tbody {
    border: none;
  }

  th, td {
    max-width: 80dvw;
  }

  th {
    text-align: left;
    line-height: 20px;
    color: $blue;
    font-weight: $font-semi-bold;
    border-bottom: thin solid $table-grey;
    border-left: thin solid $table-grey;

    &:first-child {
      border-left: none;
    }
  }

  td {
    vertical-align: middle;
    text-align: left;
    padding: 0.5rem !important;
  }

  tr {
    &:nth-child(even) {
      background-color: $column-blue;
    }
  }

  td + td {
    border-left: thin solid $table-grey;
  }

  &_td-empty {
    text-align: center !important;
  }
}

.react-paginate li {
  background-color: var(--white);
}
