@import "../../../../../assets/styles/media.scss";

.filters-accordion-header{
  padding: 0;
}

.filters-mobile {
  width:100%;
  padding: 16px;
  background: var(--grey-2, #E9E9E9);

}

button {
  color: var(--black, #333);
  line-height: 24px; /* 171.429% */
}

@include small-layout {
.filters-height {
  height:  400px;
}
}
