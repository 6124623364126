@import "../../../../../assets/styles/variables";

.map {
  max-width: 80vw;

  .modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__submit {
    margin-top: 10px;
    max-width: 40vw;
  }

  ul {
    list-style: none;
  }

  &__event-info {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    h5 {
      margin: 0;
      font-size: $large-font-size;
      font-family: $body-font;
      font-weight: $font-bold;
    }
  }

  &__filters {
    width: 100%;

    &_form {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 12px;

        .default-formik-input {
          width: 100%;
        }
      }

      &_wrapper select, input {
        border: 1px solid $inputs-grey;
        border-radius: $default-input-border-radius;
        height: $default-input-height;
        width: 100%;
        padding-inline: 10px;
      }
    }
  }

  &__events {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 10px;
    gap: 10px;
    width: 100%;

    &_block {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &_group {
      width: 100%;
      height: 30vh;
      overflow-y: scroll;
      border-radius: 5px;
      border: thin solid #ced4da;
    }

    &_item {
      min-height: 50px;
      height: fit-content;
      width: 100%;
      background-color: var(--white);
      border-bottom: thin solid #ced4da;

      &_input {
        display: none;
      }

      &_input:checked + &_label {
        background-color: var(--main-color);
        color: var(--white);
      }

      &_label {
        min-height: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
      }
    }

    &_item-no {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
