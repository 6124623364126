.request-event-form {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_inputs {
    display: flex;
    justify-content: space-between;
  }

  span {
    font-size: 12px;
    color: var(--main-color)
  }

  button {
    align-self: center;
  }
}

.input-width {
  width: 100%;
}
