@import "../../../assets/styles/variables";

.header-dropdown-menu {
  &__toggle {
    cursor: pointer;
    font-family: $body-font;
    font-size: $large-font-size;
  }

  &__menu {
    font-family: $body-font;
    font-size: $large-font-size;
    position: absolute;
    top: 100%;
    background: #fff;
    box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
  }
}
