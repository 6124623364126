@import "../../../assets/styles/mixins";
@import "../../../assets/styles/variables";

.admin-users-page {
  &__title {
    font-family: $body-font;
    font-weight: $font-bold;
    font-size: $larger-font-size;
    line-height: 44px;
    color: #173454;
    margin-top: 1rem;
  }

  &__filters {
    display: flex;
    justify-content: space-between;
  }

  &__filter-btn {
    @include main-button(true);
    padding: 10px 40px;
    border: 1px solid var(--main-color) !important;

    &.active {
      background: var(--main-color);
      border: 1px solid var(--main-color) !important;
      color: #fff;
    }

    &:not(&:last-child) {
      margin-right: 10px;
    }
  }

  &__search-input-wrapper {
    position: relative;
  }

  &__search-input {
    @include main-input();
    padding: 10px 10px;
  }

  &__search-input-icon {
    position: absolute;
    height: 25px;
    width: 25px;
    right: 10px;
    top: 12px;
    user-select: none;
    background-color: white;
    border: none;
  }

  &__users-table-wrapper {
    padding: 25px 40px;
    background: #FFFFFF;
    box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
    border-radius: 5px;
    height: 50vh;

    table {
      border-collapse: separate;
    }

    th {
      text-align: center;
      line-height: 20px;
      color: #9A1032;
    }

    td {
      vertical-align: middle;
      text-align: center;
      padding: 0.1rem 0.1rem !important;
    }
  }

  &__dropdown-item {
    &:active {
      background: var(--main-color);

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
      }
    }
  }

  &__tr {
    td {
      white-space: normal;

      &:nth-child(1) {
        width: 10%;
      }

      &:nth-child(2) {
        width: 30%;
      }

      &:nth-child(3) {
        width: 10%;
      }

      &:nth-child(4) {
        width: 30%;
      }

      &:nth-child(5) {
        width: 15%;
      }

      &:nth-child(6) {
        width: 5%;
      }
    }
  }

  &__action-icon {
    cursor: pointer;
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }

  &__percent-form {
    display: flex;
    gap: 16px;
    max-width: 200px;
  }

  &__percent-invisible {
    display: none;
  }

  &__percent-visible {
    display: inherit;
  }
}
