.import-file {
    display: flex;
    flex-direction: column;
    gap: 15px;

    &__input::-webkit-file-upload-button {
        height: 40px;
        background: #9A9A9A;
        border: 1px solid #9A9A9A;
        border-radius: 5px;
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: 400;
        color: var(--white);

        &:hover {
            background: var(--white);
            color: var(--main-color);
        }
    }

    &__button {
        height: 40px;
        border: 1px solid var(--main-color);
        background: var(--main-color);
        border-radius: 5px;
        font-family: "Gilroy", sans-serif;
        font-style: normal;
        font-weight: 400;
        color: #fff;

        &:hover {
            background: var(--white);
            color: var(--main-color);
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    &__dropdown {
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background: var(--main-color);
        border-color: var(--main-color);
        padding: 0;

        &:after {
            content: none;
        }

        &:hover, &:focus {
            background: var(--white);
            color: var(--main-color);
            border-color: var(--main-color);
            box-shadow: none;
        }

        &:active {
            background: var(--main-color);
            border-color: var(--main-color);
            color: var(--white);
        }
    }

    .show>.btn-primary.dropdown-toggle{
        background: var(--main-color);
        border-color: var(--main-color);
        color: var(--white);
        box-shadow: none;
    }

    &__examples {
        &__p-bold {
            font-weight: 700;
        }

        &__wrapper p {
            margin: 0 1rem;
            &:nth-child(1) {
                margin: 1rem 0 0;
            }
        }
    }
}