@import "src/assets/styles/variables";

.header-wrapper {
  position: relative;
  height: var(--header-heght);
}

.release-note-button {
  background-color: transparent;

  margin: 0 !important;
}

.header {
  position: fixed;
  height: var(--header-heght);
  z-index: 20;
  width: 100%;
  background: var(--main-color);

  &__navbar {
    height: 100%;
  }

  &__container {
    height: 100%;
    padding: 0 16px;
  }

  &__content {
    display: flex;
    align-items: center;
    height: 100%;
  }
}

.header-wrapper.header-opened-white .header {
  transition: background-color 0.5s ease-in-out;
  background-color: $white;
}

.header-wrapper.header-opened-default .header {
  transition: background-color 0.5s ease-in-out;
  background-color: $red;
}

