@import "../../../../../assets/styles/media.scss";

.wanted-ticket-tooltip {
  left: -50px;
}

.event-info {
  & h5 {
    text-align: center;
    margin: 0;
  }
}

.loader-container {
  position: absolute;
  top: 50%;
  right: 30%;
}

.find-events-sidebar {
  background: #E9E9E9;
  position: relative;


  &-toggle {
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: 5px;
    top: 31px;
    z-index: 10;
    text-align: center;
  }
}

.td_max-width {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.wanted-tickets {
  &__thead {
    background-color: var(--white);
    text-align: center;
    line-height: 20px;
    color: #9A1032;
    border-bottom: 1px solid #E9E9E9;
  }

  &__tbody {
    border-top: none !important;
  }

  &__tr {
    height: 70px;
    background-color: var(--white)
  }

  &__tr:nth-child(even) {
    background-color: #E9E9E9;
  }

  &__td {
    vertical-align: middle;
    text-align: center;
    padding: 0;
    line-height: 16px;

    &:nth-child(7) {
      width: 10%;
    }

    &:nth-child(9) {
      width: 10%;
    }
  }

  &__button {
    border: 1px solid var(--main-color);
    background: var(--main-color);
    border-radius: 5px;
    color: #fff;
    padding: 4px;
    width: 100%;

    &_delete {
      &:hover {
        color: #000;
        background: var(--white);
        border: 1px solid var(--main-color);
      }
    }

    &_offer {
      color: #000;
      background: var(--white);
      border: 1px solid var(--main-color);
      margin: 10px auto;

      &:hover {
        color: #fff;
        background: var(--main-color);
      }
    }
  }
}

.wanted-table-container {
  width: 100%;
  margin: 0 30px;
  overflow: auto;
  background-color: white;
}

.no-border {
  border: none;
}

@include tablet-1024 {
  .wanted-table-container {
    margin: 0 0 0 60px;
    min-width: 100%;
    z-index: 2;
  }

  .find-events-sidebar {
    position: absolute;
    z-index: 3;
    background: #E9E9E9;
  }
}

@include tablet-744 {
  .wanted-table-container {
    margin: 0 0 0 40px;
  }
  .find-events-sidebar {
    &-toggle {
      right: 13px;
    }
  }
}

@include small-layout {
  .loader-container {
    top: 60%;
    left: 40%;
  }

  .wanted-tickets {

    &__table {
      margin: 30px 0;
    }
  }

  .find-events-sidebar {
    display: none;
  }

  .matches-title {

    &_mobile {
      margin: 30px 0 0 0;
    }
  }

  .wanted-table-container {
    margin: 0;
  }

  .nav-tabs {
    margin: 0;
  }
}
