@import "../../../../../assets/styles/mixins";
@import "../../../../../assets/styles/variables";

.odd-even-reset {
  width: 1px !important;
  margin: 0 !important;
  min-width: 1px !important;
  padding: 0 !important;
}
td.odd-even-reset {
  background-color: $table-grey;
}

.user-sell-page {

  &__table {
    >tbody >tr >td {
      border-bottom: 1px solid $table-grey;
    }

    &-title {
      background: var(--blue2);
      border-bottom: 1.5px solid var(--grey2);

      &-text {
        color: var(--dark_blue);
        font-weight: $font-bold;
        line-height: 24px;
      }

      &-add {
        content: url("../../../../../assets/images/sell-page/addCross.svg");
        padding: 6px 12px;
        background: var(--white);
        border-radius: 5px;
        cursor: pointer;
      }
    }

    &-text {
      line-height: 16px;
      color: var(--blue);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:nth-child(odd) {
        background: #F0F2F5;
      }
    }

    &-custom-price {
      position: relative;
      max-width: 200px;
    }

    &-own {
      line-height: 16px;
      color: var(--blue);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      background-color: #dff0d8;

      &:nth-child(odd) {
        background: #d2e5ca;
      }
    }

    &-section {
      white-space: normal;
    }

    .hold-alert {
      margin: 5px auto 0;
      color: white;
      font-weight: bold;
      border-radius: 8px;
      padding: 4px 8px;
      background: red;
      max-width: 80px;
    }

    &-actions {
      display: flex;
      padding-left: 31px;
    }

    &-action-btn {
      @include main-button(true);
      padding: 8px 25px;

      &:not(&:last-child) {
        margin-right: 20px;
      }
    }
  }

  &__action-button, .dropdown-toggle {
    &:after {
      content: none;
    }
  }

  &__dropdown-item {
    &:active {
      background: var(--main-color);

      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(73deg) brightness(103%) contrast(103%);
      }
    }
  }
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 150px;
  &.with-prefix {
    width: 180px;
  }

  &__price-invisible .spinner-border {
    display: none;
  }

  &__price-invisible &__price {
    display: block;
  }

  &__price-visible .spinner-border {
    display: block;
    width: 1.5rem !important;
    height: 1.5rem !important
  }

  &__price-visible &__price {
    display: none;
  }
}

.price-container .default-formik-input {
  width: 60%;
}

.hover-text {
  position: relative;
  text-align: center;
  max-width: 140px;
  background: #FFFFFF;
  border-bottom: 1px solid #DAE3EE;

  &_own {
    position: relative;
    text-align: center;
    max-width: 140px;
    border-bottom: 1px solid #DAE3EE;
    background-color: #dff0d8;
  }

  &_odd {
    background: #F0F2F5;
  }
}

.text-container {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  font-size: 12px;
}

.tooltip-text {
  position: absolute;
  visibility: hidden;
  z-index: 1;
  width: 280px;
  top: 45px;
  left: -70px;
  word-break: break-word;
  color: white;
  background-color: var(--dark_blue);
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
}

.no-top-margin {
  margin-top: 0;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: #3B4A5D;
  z-index: 2;
}

.inherit-tr {
  position: inherit;
}
