.pool-container {
  max-width: 475px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  gap: 10px;
  padding: 5px 10px;
}

.pool-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: 50%;

  p {
    margin: 0;
  }

  &_button {
    max-width: 100px;
    width: 100%;
    background-color: var(--main-color);
    border-radius: 5px;
    color: white;
  }
}
