@import "../../../../../../assets/styles/mixins";
@import "../../../../../../assets/styles/variables";

.inventory-integrations {
  .rs-divider {
    margin: 0 6px;
  }
  &:hover {
    cursor: pointer;
    .text-primary.option {
      color: #183553 !important;
    }
  }
  &-popover {
    min-width: 250px;
    .price-input {
      width: 80px;
      @include placeholder {
        opacity: 0.6;
      }
    }
  }
}
