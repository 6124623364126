.invoice-modal {
  button {
    margin: 0 auto;
  }
}

.invoice-edit-form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.invoice-email {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_inputs {
    display: flex;
    justify-content: space-between;
  }
}

.invoice_info {
  display: flex;
  gap: 16px;
  border-bottom: 1px solid rgba(#9A1032, 50%);
  justify-content: space-between;

  &-container {
    display: flex;
    flex-direction: column;
    gap: 8px;


    & p {
      margin: 0;
    }
  }
}

.invoice_inputs {
  display: flex;
  flex-direction: column;

  &-container {
    gap: 8px;
    display: flex;
    justify-content: space-between;
  }
}
