@import "src/assets/styles/variables";

.header-logo {
  color: #fff;
  font-family: 'Josefin Slab', serif;
  font-size: 24px;
  text-decoration: none;

  &:hover {
    color: #fff;
  }
}

.header-wrapper.header-opened-white .header-logo {
  color: $red;
}
