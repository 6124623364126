.api-keys {
  display: flex;
  flex-direction: column;

  &_header {
    margin: 0;
  }

  &_input-name{
    margin: 0;
    padding-top: 10px;
    padding-bottom: 4px;
  }

  .form-fields {
    display: flex;
    justify-content: space-between;

    .field {
      width: 48%;
    }
  }

  .self-align {
    align-self: flex-start;
  }

  .api-input{
    display: flex;
    flex-direction: column;
  }

  .field {
    display: flex;
    flex-direction: column;
  }
}
