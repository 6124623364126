@import "src/assets/styles/variables";

.default-formik-input {
  position: relative;

  input {
    width: 100%;
    cursor: pointer;
    height: $default-input-height;
    color: #212529;
    padding: 0.375rem 0.75rem;
    border-radius: $default-input-border-radius;
    border: thin solid $inputs-grey;

    &.with-prefix {
      border-radius: 0 $default-input-border-radius $default-input-border-radius 0;
    }
  }
}

.input-label-wrapper {
  position: relative;
  z-index: 1;
}
.input-label {
  position: absolute;
  font-size: 10px;
  top: 0;
  left: 0;
  height: 16px;
  transform: translateY(-8px);
  border: 1px solid $inputs-grey;
  border-radius: $default-input-border-radius $default-input-border-radius 0 0;
  border-bottom: 0;
  background: #fff;
  padding: 0 4px;
  color: var(--bs-gray);
  white-space: nowrap;
  &:after {
    display: block;
    position: absolute;
    content: '';
    height: 50%;
    width: $default-input-border-radius;
    right: 0;
    bottom: 0;
    background: #fff;
    transform: translateX(50%);
  }
}

.addon {
  top: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;

  &.prefix {
    left: 0;
    justify-content: center;

    > div {
      display: flex;
      align-items: center;
      height: $default-input-height;
      background: #F0F2F5;
      padding: 0.375rem;
      border: thin solid $inputs-grey;
      border-right: none;
      border-radius: $default-input-border-radius 0 0 $default-input-border-radius;
    }
  }

  &.end-icon {
    position: absolute;
    right: 0;
    justify-content: flex-end;

    img {
      padding-right: .5rem;
    }
  }
}

.form-control.is-invalid {
  background-image: unset;
  padding-right: 16px;
}
