@import "../../../../assets/styles/variables";

.event-seating-plans {
  width: 100%;

  .options {
    align-items: stretch;
    max-height: 0;
    overflow: hidden;
    transition: all .5s ease-in-out;
    width: 100%;
    padding: 0 1px;

    &.expanded {
      max-height: 100px;
      overflow-x: auto;
    }

    .option-item {
      flex-basis: 15dvw;
      flex-grow: 1;
      max-width: 25dvw;

      &:hover {
        .event-seating-plan {
          background-color: $white;
          border: 1px solid $red;

          &:not(.selected) {
            cursor: pointer;
          }
        }
      }

      .event-seating-plan {
        height: 100%;
        overflow-y: hidden;
        border: 1px solid $inputs-grey;
        justify-content: center;

        .additional-label {
          text-align: right;
          font-size: 12px;
          >* {
            display: none;
          }
        }

        &.with-mapping {
          border-color: $red;
          .additional-label {
            border-bottom: 1px solid $inputs-grey;
            >* {
              display: inline;
            }
          }
        }
        &.selected {
          border-color: $red;
          background-color: $red;
          color: $white;
        }
      }
    }
  }

  .loader-overlay {
    border-radius: 4px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
