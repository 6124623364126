@import "../../../assets/styles/variables";

.otp-form {
  &__verification-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #173454;
  }

  &__verification-sub-title {
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #173454;
  }

  &__resend-code-block {
    margin-top: 15px;
    width: $mobile-width;

    p {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      color: #173454;
    }
  }

  &__error-block {
    cursor: pointer;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #9A1032;
  }
}

