@import "../../../../assets/styles/mixins";
@import "../../../../assets/styles/media.scss";
@import "../../../../assets/styles/variables";

.find-events {
  max-width: 420px;
  padding: 1rem 1rem 0 1rem;

  &-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & input {
      height: 40px;
      width: 100%;
      border-radius: 5px;
    }
  }

  &-search, &-date, &-event-category, &-event-id {
    display: flex;
    gap: 16px;

    & input {
      padding: 0 12px;
    }
  }

  &-button {
    display: flex;
    gap: 16px;
    height: 40px;

    &-apply {
      @include main-button();
      width: 60%;
    }

    &-reset {
      @include main-button();
      width: 40%;
      background: #9A9A9A;
    }
  }

  &-accordion {

    &-header {

      & button {
        font-weight: bold;
        color: #173454;
      }
    }

    &-body {
      padding: 0;
    }

  }

  &-expand {
    width: 420px;
    height: 100%;
  }

  &-collapse {
    width: 57px;
  }
}

.add-inventory {
  min-width: calc(100% - var(--sell-page-filter-max-size));
  width: 100vw;
  overflow-y: auto;
  padding: 2rem 1rem;
  @include tablet() {
    padding: 2rem 2rem;
  }
  @include desktop() {
    padding: 2rem 3rem;
  }
  @include custom-scroll();

  &-title {
    font-size: $larger-font-size;
    font-family: $body-font;
    font-weight: $font-bold;
    color: var(--blue);
  }

  &-event-title {
    max-width: 100%;
    font-size: $large-font-size;
    font-family: $body-font;
    font-weight: $font-bold;
    margin: 30px 0 10px;
  }

  &-progress-bar {
    display: flex;
    gap: 16px;
    margin: 24px 0;

    &-step {
      background: var(--blue2);
      align-items: center;
      padding: 12px 32px;
      border-radius: 5px;
      color: var(--blue);

      &-active {
        border: 1px solid var(--blue);
      }
    }

    &-button {
      color: var(--white);
      background: var(--blue);
      border-radius: 5px;
      padding: 12px 32px;
      border: none;
      outline: none;
    }
  }

  &-form {
    color: var(--blue);
    display: flex;
    gap: 16px;
    flex-direction: column;

    &-label {
      line-height: 17px;
    }

    &-input {
      box-sizing: border-box;
      border: 1px solid #94A6BD;
      border-radius: 5px;
      height: 35px;
      min-width: 250px;
      padding: 0.375rem 0.75rem;
    }

    &-submit-btn {
      @include main-button();
      height: 46px;
      max-width: 250px;
      width: 100%;
    }

    &-hint {
      position: relative;
      border-radius: 50%;
      background: var(--main-color);
      border-color: var(--main-color);
      color: var(--white);
      padding-inline: 5px;
      margin-left: 5px;
    }

    &-tooltip {
      position: absolute;
      visibility: hidden;
      width: 120px;
      top: 110%;
      left: -50%;
      border-radius: 5px;
      padding: 5px;
      background-color: var(--white);
      color: var(--main-text-color);
      border: 1px solid #94A6BD;
      z-index: 1;
    }

    &-hint:hover &-tooltip {
      visibility: visible;
    }
  }
}

.filters .accordion {
  height: fit-content;
}

.matches .tab-content {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.matches {
  height: 100%;
  overflow: auto;

  @include custom-scroll();

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &-title {
    font-weight: $font-bold;
    font-size: $large-font-size;
    font-family: $body-font;
  }

  &-sort {
    width: 166px;
    height: 40px;
    padding: 0 16px;
    margin-top: 8px;
    border-radius: 5px;
    appearance: none;
    background: right 1rem center url("../../../../assets/images/sell-page/AccordionArrow.svg") no-repeat;
  }

  &-filters {
    height: fit-content;
  }

  &-body {
    &-search {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      margin-bottom: .5rem;

      &-default {
        padding: 0 12px;
        border: 1px solid var(--light_grey);
      }
    }

  }

  &-list {
    overflow: auto;
    border-radius: 5px;
    margin-top: .5rem;

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: rgba(59, 74, 93, 0.1);
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba(59, 74, 93, 0.2);
      border-radius: 12px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  &-add-inventory {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-header-add-inventory {
    max-width: 300px;
  }
}

.checkbox-field input {
  margin-left: 10px;
}

input {
  accent-color: var(--main-color);
}

.split-type {

  &__custom {
    display: flex;
    width: 250px;
    flex-wrap: wrap;
    gap: 3px;
    margin-top: 10px;

    label {
      width: 30px;
      height: 30px;
      border: 1px solid #94A6BD;
      border-radius: 5px;
      background-color: var(--white);
      cursor: pointer;

      span {
        text-align: center;
        padding: 3px 0;
        display: block;
      }

      input {
        position: absolute;
        display: none;
        color: #fff !important;
      }

      input:checked + span {
        color: var(--white);
        border-radius: 5px;
        background-color: var(--main-color);
      }
    }
  }
}

@include tablet-1024 {
  .find-events-sidebar {
    position: fixed;
    z-index: 7;
    height: calc(100vh - var(--header-heght));
  }
}

@include tablet-744 {
  .find-events {
    &-collapse {
      max-width: 40px;
    }
  }

  .checkbox-field {
    display: flex;
    align-items: center;
  }
}

@include small-layout {
  .checkbox-field {
    display: flex;
    gap: 30px;
  }

  .form-container {
    max-width: 358px;
    width: 100%;
    gap: 10px;

    &_double {
      display: flex;
      gap: 16px;

      &-column {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      &-input {
        max-width: 170px;
        margin: 0;
      }
    }

    select {
      max-width: 358px;
      width: 100%;
    }

  }

  .add-inventory {
    max-width: $mobile-width;
    margin: 0;

    &-event-title {
      margin: 0 16px;
    }

    &-form {
      flex-direction: column;
      align-items: center;
      margin: 0 .5rem;

      &-submit-btn {
        margin: 0 0 24px 0;
      }

    }

    &-title {
      margin: 24px 16px 0;
      color: var(--blue, #173454);
      font-weight: $font-bold;
      font-size: $larger-font-size;
      font-family: $body-font;
      line-height: normal;
    }

    &-progress-bar {
      margin: 24px 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      &-step {
        padding: 8px 0;
        width: 140px;
        text-align: center;
        height: 40px;
        color: var(--blue, #173454);
        line-height: normal;
      }

      &-button {
        width: 63px;
        height: 40px;
        color: var(--white, #FFF);
        text-align: center;
        line-height: normal;
        padding: 0;
      }
    }
  }

  .matches {
    display: block;

    &-header {
      margin: 0 1rem;
    }

    &-body {
      margin: 0 1rem;
    }

    &-list {
      max-height: 282px;
      background-color: var(--grey-2, #E9E9E9);
      border: 1px solid var(--main-color);
      margin-bottom: 24px;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .match-card {
    &-name {
      color: var(--blue, #173454);
      font-weight: $font-bold;
      line-height: normal;
      margin-top: 8px;
    }

    &-description {
      &-stadium {
        color: var(--black, #333);
        line-height: normal;
        text-transform: uppercase;
      }

      &-country {
        color: var(--grey, #9A9A9A);
        font-weight: $font-bold;
        line-height: normal;
        margin: 0;
        padding: 2px 0 0 0;
      }

      &-place {
        max-width: 200px;
      }
    }
  }
}

.tag-picker, .disclosure-picker {
  min-width: 250px;
  border: 1px solid #94A6BD;
  border-radius: 5px;
}

.disclosure-picker {
  height: auto !important;
}

.seats-tooltip {
  position: absolute;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  margin-top: 0.1rem;
  background-color: var(--white);
  color: var(--main-text-color);
  border: 1px solid #94A6BD;
  z-index: 10;
}

.add-inventory-form-main-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  max-width: 782px;
  justify-content: space-between;
}

.po-data {
  &-container {
    display: flex;
    flex-wrap: wrap;
    max-width: 782px;

    h5 {
      font-size: $large-font-size;
      font-family: $body-font;
      font-weight: $font-bold;
      padding: 16px 0 0;
    }
  }

  &-inputs {
    &_single-input {
      display: flex;
      flex-direction: column;

      & button {
        max-width: 60px;
      }
    }
  }
}

.vendor-input-container {
  display: flex;
  gap: 8px;
  flex-direction: column;
}


.split-type-hint-width {
  width: 300px;
  left: -450%;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}

.modal-backdrop.show:nth-last-child(2) {
  z-index: 1100;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}

.modal-backdrop.show:nth-last-child(4) {
  z-index: 1050;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
  z-index: 1025;
}

.modal-backdrop.show:nth-last-child(6) {
  z-index: 1000;
}

.events-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 1rem;
  background: $white;
  border: 1px solid $inputs-grey;
  padding: .5rem;
  max-width: 782px;
  width: 100%;
  border-radius: 5px;

  &__item {
    display: flex;
    justify-content: space-between;
    gap: .5rem;
    align-items: center;

    p {
      margin: 0;
    }
  }
}

@include mobile {
  .events-container {
    margin: .5rem;
    max-width: 400px;
  }
}
