@import "src/assets/styles/variables";

.app-loader {
  display: inline-block;
  border-style: solid;
  border-color: #FFF;
  border-bottom-color: $red;
  border-radius: 50%;
  animation: app-loader-rotation 1s linear infinite;

  &-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes app-loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
