@import "../../../assets/styles/variables";

.edit-event {
  width: 100%;

  &__white_button {
    border-color: $white;
  }

  &__selected {
    background-color: $red;
    color: $white;
  }

  .events-page__table__tr {
    cursor: initial;
  }

  .app-paginated-table-wrapper {
    width: 100%;
    height: 500px;
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    h5 {
      margin: 0;
      font-size: $large-font-size;
      font-family: $body-font;
      font-weight: $font-bold;
    }
  }

  &-search-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-bottom: .5rem;

    &__inputs {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: .5rem;

      > * {
        width: 100%;
      }
    }

    &__button {
      width: 30%;
    }
  }

  &-form {
    width: 100%;
    &__results {
      display: flex;
      flex-direction: column;
      height: 20rem;
      width: 100%;
      overflow-y: auto;
      border-radius: 8px 8px;
      padding: 12px;
    }

    &__result {
      &:not(:first-child){
        margin-top: 1rem;
      }

      input {
        margin-right: 12px;
      }
    }
  }
}
