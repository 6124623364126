.bids-table {
  margin: 10px;
  background-color: white;
  overflow-x: auto;

  &_main {
    text-align: center;
    vertical-align: baseline;
    box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);

    & thead {
      border: none;

      border-bottom: 1px solid #E9E9E9;

      & tr {

        & th {
          text-align: center;
          line-height: 20px;
          color: #9A1032;
        }


      }
    }

    tbody {
      border-top: none !important;

      & tr {
        &:nth-child(odd) {
          background: #f0f2f5;
        }
      }
    }
  }
}

.actions-container {
  margin: 0 auto;
  display: flex;
  max-width: 100px;
  gap: 8px;
  justify-content: space-between;

  &_action {
    border: 1px solid var(--main-color);
    border-radius: 5px;
    padding: 5px;

    &-accept {
      background-color: white;

      &:hover {
        background-color: var(--main-color);
        color: white;
      }
    }

    &-reject {
      background-color: var(--main-color);
      color: white;

      &:hover {
        background-color: white;
        color: #000;
      }
    }
  }
}
