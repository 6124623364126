.venues .nav-tabs .nav-link {
  background: var(--white);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--main-color);
}

.venues .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: var(--main-color);
  font-family: "Gilroy", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: var(--white);
}
