@import "../../assets/styles/mixins";

.invoice-section-page {
  white-space: nowrap;

  thead {
    position :sticky;
    top: 0;
    height: 50px;
    background-color: #FFFFFF;
  }

  &__tr {
    td {
      padding-inline: 5px !important;
    }
  }
  &_no-top-padding {
    padding: 0 40px;
  }
}