@import "src/assets/styles/variables";
@import "src/assets/styles/mixins";
@import "src/assets/styles/media.scss";

.invoice-custom-error {
  position: absolute;
  top: 5px;
  left: 20px;
}

.invoice-total-line {
  font-size: $large-font-size;
  font-weight: $font-semi-bold;
}

.invoice-actions-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 3rem 6.25rem;
  margin: 0 auto;

  h1 {
    margin: 0;
  }
}

.no-radius-table-border {
  height: unset;
  min-height: unset;
  box-shadow: unset;
  overflow: unset;
  @include reset-border-radius;
}

.invoice-info {
  width: 100%;
  background-color: $white;

  table {
    margin: 0;
    border-bottom: thin solid $inputs-grey;

    thead {
      tr {
        background-color: $blue;

        th {
          font-weight: $font-normal;
          color: $white;
          border: none;
        }
      }
    }
  }
}

.invoice-info td {
  border: none;
  font-size: $normal-font-size;
  font-family: $table-font;
  font-weight: $normal-font-size;
  background-color: $column-blue;

  .app-date-picker, select, .default-formik-input {
    width: 100%;
  }

  input, select {
    background-color: transparent;
    padding: .3rem 0.75rem;
  }

  span {
    align-self: center;
  }

  &:nth-child(1) {
    min-width: 50px;
  }

  &:nth-child(2) {
    min-width: 100px;
  }

  &:nth-child(3) {
    min-width: 164px;
  }

  &:nth-child(4) {
    min-width: 126px;
    line-height: 40px;
  }

  &:nth-child(7) {
    min-width: 160px;
  }

  &:nth-child(8) {
    min-width: 188px;
  }

  &:nth-child(9) {
    min-width: 193px;
  }
}

.invoice-buttons {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  &__item {
    width: 100%;
  }
}

.invoice-form {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  select {
    border: thin solid $inputs-grey;
    border-radius: 5px;
  }

  &__block {
    p {
      margin: 0;
    }
  }

  &__block-header {
    display: flex;
    width: 100%;

    &_first {
      display: flex;
      flex-basis: 30%;
      justify-content: space-between;
      background-color: $light-grey;
    }

    &_second {
      width: 100%;
      display: flex;
      background-color: $blue;

      p {
        width: 100%;
      }
    }

    p {
      color: #FFFFFF;
      text-align: left;
      padding: .625rem .5rem;
    }
  }

  &__block-content {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &_first {
      display: flex;
      flex-basis: 30%;
      justify-content: space-between
    }

    &_second {
      width: 100%;
      display: flex;

      p {
        width: 100%;
      }
    }

    p {
      color: #000;
      text-align: left;
      padding: .625rem .5rem;

      &:nth-child(odd) {
        background: $light-grey;
      }
    }
  }
}

.customer-block {
  display: flex;
  gap: 1.5rem;

  &__search {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__customer-info {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    select {
      background-color: transparent;
      border: thin solid $inputs-grey;
      padding: .5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__selectors {
    display: flex;
    gap: 1rem;

    select {
      flex-basis: 50%;
    }
  }

  &__billing-info {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__billing-data {
    padding: 0.75rem 0 2rem 1rem;
    border: thin solid $inputs-grey;
    border-radius: 5px;
    min-height: 190px;
    height: 100%;
  }

  &__header {
    font-size: $semi-large-font-size;
    font-weight: $font-bold;
    font-family: $body-font;
    color: $blue;
    margin: 0;
    padding-bottom: 0.25rem;
    border-bottom: thin solid $inputs-grey;
  }
}


.invoice-lines {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  table {
    border-bottom: none;

    tbody {
      tr {
        &:last-child {
          height: 40px;
        }
      }
    }
  }
}

.invoice-lines td {
  background-color: $column-blue;

  &:nth-child(1) {
    min-width: 50px;
  }

  &:nth-child(2) {
    min-width: 90px;
  }

  &:nth-child(3) {
    min-width: 60px;
  }

  &:nth-child(4) {
    min-width: 258px;
  }

  &:nth-child(5) {
    min-width: 232px;
  }

  &:nth-child(6) {
    min-width: 100px;
  }

  &:nth-child(7) {
    min-width: 95px;
  }

  &:nth-child(8) {
    min-width: 95px;
  }

  &:nth-child(9) {
    min-width: 40px;
  }

  &:nth-child(10) {
    min-width: 90px;
  }

  &:nth-child(11) {
    min-width: 90px;
  }

  &:nth-child(12) {
    min-width: 100px;
  }

  &:last-child {
    text-align: left;
  }
}

.due-date-container {
  display: flex;
  gap: .5rem;

  img {
    align-self: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 18px;
    width: 18px;
  }
}

.notes-block {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__container {
    display: flex;
    gap: 1rem;
  }

  &__container-item {
    display: flex;
    flex-basis: 50%;
    flex-direction: column;

    p {
      margin: 0;
      padding-bottom: 0.25rem;
      color: $blue;
      font-weight: $font-normal;
      font-family: $table-font;
    }

    textarea {
      resize: none;
      min-height: 7.5rem;
      border: thin solid $inputs-grey;
      border-radius: 5px;
      font-size: $normal-font-size
    }
  }
}

.invoice-lines tr {
  &:last-child {
    td {
      text-align: right;
      background-color: $column-blue;
      border: none;

      &:last-child {
        text-align: left;
      }
    }
  }
}

.email-block {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__inputs {
    display: flex;
    gap: 1rem;
  }

  &__item {
    flex-basis: 25%;

    p {
      font-family: $table-font;
      font-weight: $font-normal;
      font-size: $normal-font-size;
      color: $blue;
      margin: 0;
      text-align: left;
      padding-bottom: 0.25rem;
    }
  }
}

@include tablet-1024 {
  .due-date-container {
    input {
      padding: 0.3rem 0 !important;
    }
  }

  .invoice-actions-container {
    padding: 2.25rem 2.5rem;
  }

  .invoice-info td {
    &:nth-child(1) {
      min-width: 40px;
    }

    &:nth-child(2) {
      min-width: 100px;
    }

    &:nth-child(3) {
      min-width: 91px;
    }

    &:nth-child(4) {
      min-width: 130px;
    }

    &:nth-child(5) {
      min-width: 98px;
    }

    &:nth-child(6) {
      min-width: 80px;
    }

    &:nth-child(7) {
      min-width: 150px;
    }

    &:nth-child(8) {
      min-width: 118px;
    }

    &:nth-child(9) {
      min-width: 158px;
    }
  }

  .invoice-lines td {
    &:nth-child(1) {
      min-width: 46px;
    }

    &:nth-child(2) {
      min-width: 85px;
    }

    &:nth-child(3) {
      min-width: 90px;
    }

    &:nth-child(4) {
      min-width: 164px;
    }

    &:nth-child(5) {
      min-width: 138px;
    }

    &:nth-child(6) {
      min-width: 94px;
    }

    &:nth-child(7) {
      min-width: 78px;
    }

    &:nth-child(8) {
      min-width: 89px;
    }

    &:nth-child(9) {
      min-width: 80px;
    }

    &:nth-child(10) {
      min-width: 80px;
    }
  }
}

@include tablet-744 {
  .invoice-actions-container {
    max-width: 680px;
    padding: 2.25rem 2rem;
  }

  .customer-block {
    gap: 1rem;

    &__selectors {
      flex-direction: column;
    }
  }

  .email-block {
    &__inputs {
      max-width: 450px;
      width: 100%;
    }

    &__item {
      flex-basis: 50%;
    }
  }
}

@include small-layout {
  .invoice-actions-container {
    padding: 2.25rem 1rem;
  }

  .customer-block {
    flex-direction: column;
  }

  .notes-block {
    &__container {
      flex-direction: column;
    }
  }

  .email-block {
    &__inputs {
      flex-direction: column;
    }
  }

  .invoice-buttons {
    flex-direction: column;
  }
}
