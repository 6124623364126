.delete-confirmation-modal {
  display: flex;
  flex-direction: column;
  text-align: center;

  &__text {
    align-self: center;
    margin: 2.5rem;

  }

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #9A1032;
  }

  &__buttons {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin: 0 2.5rem 1rem;

    div {
      flex-basis: 50%;
    }
  }
}
