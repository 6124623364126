*, :after, :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0
}

[hidden], template {
    display: none
}

a {
    background-color: transparent
}

a:active, a:hover {
    outline: 0
}

b, strong {
    font-weight: 700
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0
}

svg:not(:root) {
    overflow: hidden
}

hr {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    height: 0
}

pre {
    overflow: auto
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    border-radius: 0;
    overflow: visible
}

button, select {
    text-transform: none
}

button, html input[type=button], input[type=reset], input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer
}

button[disabled], html input[disabled] {
    cursor: not-allowed
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox], input[type=radio] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    height: auto
}

textarea {
    overflow: auto
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td, th {
    padding: 0
}

[tabindex="-1"] {
    outline: none
}

button, input[type=button], input[type=reset], input[type=submit] {
    border-width: 0
}

input::-ms-clear {
    display: none
}

input[type=file]::-ms-browse, input[type=file]::-webkit-file-upload-button {
    background: transparent;
    border-width: 0;
    color: currentColor
}

button, input, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}
