.split-form {
  &_select {
    display: flex;

    &_field {
      box-sizing: border-box;
      background-color: white;
      border: 1px solid #94A6BD;
      border-radius: 5px;
      font-style: normal;
      margin: 0 .5rem;
    }
  }
  &_groups {
    display: flex;
    flex-direction: column;
  }
}
