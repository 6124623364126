.reset-password-request {
  width: 100%;

  input {
    margin-bottom: 20px;
  }

  &__title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #173454;
  }

  &__sub-title {
    font-family: "Gilroy", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #173454;
  }

  &__result {
    margin-top: 15px;
  }

  &__row-under-form {
    margin-top: 15px;
  }
}
