@import "src/assets/styles/variables";

.app-toggle {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  > * {
    cursor: pointer;
  }

  .rs-toggle-checked {
    .rs-toggle-presentation {
      background-color: var(--main-color);
      border-color: var(--main-color);
    }
  }

  &-disabled {
    cursor: not-allowed;
    > * {
      cursor: not-allowed;
    }
  }

  &-label {
    order: 1;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }
}
