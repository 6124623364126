.pointer {
  cursor: pointer;
}

.calendar-cell {
  margin: 2px 2px 0 2px;
  //padding: 4px 4px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.calendar-cell-title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: #9A9A9A;
  margin: 0;
}

.calendar-cell-date {
  width: 150px;
  padding: 16px;
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &-active {
    color: #333333;
  }

  &-inactive {
    color: #9A9A9A;
  }

  &-owner {
    background-color: #DDE5EC;
    border-radius: 5px;
    //box-shadow: 4px 4px 4px 4px #DDE5EC;
  }

  p {
    width: 100%;
    margin: 0;
  }

  &-title {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
  }

  &-vendor {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 200;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
  }
}
