@import "../../../assets/styles/variables.scss";
@import "../../../assets/styles/media.scss";
@import "../../../assets/styles/mixins.scss";

.bid-inventory-form {
  margin: 2rem 1.5rem;

  p {
    text-align: right;
  }
}

.bid-select {
  width: 100%;
  border-radius: 5px;
  border: thin solid $inputs-grey !important;
  margin: 0 !important;
  padding: .375rem .75rem;
}

.bid-form-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.bid-form-container .default-formik-input {
  width: 100%;
}

.bid-error {
  position: absolute;
  bottom: 10px;
  right: 155px;
}

@include small-layout {
  .bid-error {
    right: 135px;
  }
}
