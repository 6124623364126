.info-main {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-height: 300px;
  overflow: hidden;
  overflow-y: auto;
}

.info-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ced4da;
  border-radius: 5px;
  gap: 16px;
  padding: 5px;

  &_fields {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    div {
      min-width: 100px;
    }
  }

  p {
    margin: 0;
    text-align: left;
  }
}

.venue {
  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 82px;
  }
}
