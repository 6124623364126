.upload-form {
  &_title {
    display: flex;
    flex-direction: column;
  }

  &_event {
    display: flex;
    flex-direction: column;

    &_data {
      display: flex;
      flex-direction: row;
    }

    &_name {
      font-weight: bold;
    }
  }

  &_inventory {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &_item {
      display: flex;
      flex-direction: column;

      &_title {
        font-weight: bold;
      }
    }
  }

  &_inputs {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  &_input {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: .5rem;
  }

  &_upload {
    margin-top: 1rem;
    width: 100%;
    height: 40px;
  }
}
