@import "src/assets/styles/variables";

.app-check {
  display: flex;
  padding-left: 0;
  align-items: center;

  &-input {
    order: 2;
    margin: 0 !important;
    border: thin solid $inputs-grey;
    padding: 0.375rem !important;

    &:checked {
      background-color: var(--main-color);
      border-color: var(--main-color);
    }
  }

  &-label {
    order: 1;
  }
}

.form-check-input:focus {
  border-color: #ced4da;
  box-shadow: none;
}
