@import "../../../../../assets/styles/variables";

.match-card {
  font-weight: 700;
  width: 100%;
  padding: 16px;
  background: white;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid var(--white);

  &-selected {
    background: var(--blue2);
    border: 1px solid var(--blue);
  }

  &-description {
    color: #333333;
    display: flex;
    justify-content: space-between;

    &-place {
      max-width: 60%;
    }

    &-stadium {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: $normal-font-size;

      &:before {
        content: url("../../../../../assets/images/sell-page/match-place.svg");
        padding-right: 6px;
      }
    }

    &-calendar {
      max-width: 40%;

      & p {
        text-align: end;
      }
    }

    &-date {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: $normal-font-size;

      &:before {
        content: url("../../../../../assets/images/sell-page/match-date.svg");
        padding-right: 6px;
      }
    }

    & p {
      color: grey;
    }
  }

  &-name {
    color: #173454;
    margin: 0;
  }
}
