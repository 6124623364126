@import "src/assets/styles/variables";

.duplicate {
  &__form-po-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      flex-basis: 45%;
    }
  }

  &__form-selected-po {
    display: flex;
    justify-content: space-between;

    h5 {
      margin: 0;
    }
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__inputs-container {
    display: flex;
    justify-content: space-between;

    button {
      max-width: 45%;
    }

    select, input {
      max-width: 163px;
      width: 100%;
      display: block;
      padding: 0.375rem 0.75rem;
      font-size: $normal-font-size;
      font-weight: $font-normal;
      line-height: 1.5;
      color: #212529;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid $inputs-grey;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    }
  }
}

.split-type-align {
  align-self: center;
  justify-content: center;
}

.button-gray {
  background-color: gray;
}
