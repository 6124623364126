.ticombo-inventory {
  width: 100%;

  &__wrapper {
    width: 100%;
    max-width: 393px;;
    overflow: hidden;
  }

  &__should-push {
    width: 100%;
    color: #fff;
    background: #173454;
    padding: 11px 24px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .ticombo-inventory__input-checkbox {
      background: #173454;
      border-color: #fff;
      border-radius: 6px!important;
      width: 20px;
      height: 20px;
    }

    &.ticombo-inventory__input-checkbox_not-push {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

  &__content {
    padding: 25px;
    border: 1px solid #94A6BD;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 28px;
  }

  &__input-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .invalid-tooltip {
      position: absolute;
    }
  }

  &__input-ratio-wrapper {
    margin-bottom: 10px;
  }

  &__input-label {
    font-family: Roboto, serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #173454;
  }

  &__input {
    max-height: 35px;
    padding: 6px 16px;
    border: 1px solid #94A6BD;
    border-radius: 5px;

    &.error {
      border: 1px solid #9A1032;
    }
  }

  &__input-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  &__input-ratio-wrapper input {
    accent-color: #677BE4;
  }
  &__input-checkbox {
    max-height: 35px;
    padding: 0 16px;
    border: 1px solid #94A6BD;
    border-radius: 4px!important;
    margin-top: 0;
    margin-right: 10px;
    width: 15px;
    height: 15px;
    color: #677BE4;
  }
}
