@import "variables";

@mixin mobile {
  @media (max-width: 810px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 811px) {
    @content;
  }
}

@mixin small-layout {
  @media (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet-1024 {
  @media (max-width: 1024px) {
    @content;
  }
}

@mixin tablet-744 {
  @media (max-width: 744px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 811px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    @content;
  }
}

@mixin narrow-desktop {
  @media (min-width: 1024px) and (max-width: 1280px) {
    @content;
  }
}

@mixin wide-desktop {
  @media (min-width: 1280px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 811px) and (max-width: 1023px) and (-webkit-min-device-pixel-ratio: 1) and (orientation: landscape) {
    @content;
  }
}
