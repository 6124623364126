@import "src/assets/styles/variables";

.app-button {
  width: 100%;
  border-radius: 5px;
  color: $white;
  padding: 0 .5rem;
  height: 35px;
  min-height: 30px;

  &-sm {
    padding: 0 12px;
    height: auto;
    width: auto;
  }

  &-lg {
    padding: 0 16px;
    font-size: 120%;
  }

  &-red {
    border: 1px solid $red;
    background: $red;
  }

  &-gray {
    border: 1px solid $gray;
    background: $gray;
  }

  &:hover {
    background: #fff;
    border: 1px solid $red;
    color: $red;
  }
}
