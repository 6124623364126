@import "../../../assets/styles/variables";

$app-modal-sm: 390px;
$app-modal-md: 430px;
$app-modal-lg: 600px;
$app-modal-xl: 800px;
$app-modal-xxl: 1140px;

.modal {
  &-title {
    font-family: $body-font;
    font-size: $large-font-size;
    font-weight: $font-bold;
  }

  &-body {
    .rs-picker-toggle {
      width: 100%;
    }

    .rs-picker-input {
      width: 240px;
      max-width: 200px;
    }

    .rs-picker-toggle.rs-btn-lg {
      font-size: $normal-font-size;
    }
  }
}
