@import "src/assets/styles/mixins";
@import "src/assets/styles/media.scss";
@import "src/assets/styles/variables";

.landing {
  background: #F3F7FA;

  &__welcome-block {
    position: relative;
    height: 742px;
  }

  &__promo-block {
    position: relative;
    height: 351px;
  }

  &__cards-block {
    background-image: url(../../assets/images/landing/cards-background.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 559px;
    padding: 30px;
  }
}

.landing-welcome-block {
  &__top-points-welcome {
    position: absolute;
    top: 0;
  }

  &__handshake-pic {
    display: none;
  }

  &__bottom-points-for-image {
    position: absolute;
    bottom: 0;
    right: 438px;
  }

  &__main-img {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__main-img-line {
    position: absolute;
    top: 0;
    right: 467px;
  }

  &__info {
    position: relative;
    margin-left: var(--landing-welcome-block-info-margin-left);
    width: 600px;

    &-title {
      font-style: normal;
      font-weight: 700;
      font-size: 52px;
      line-height: 64px;
      color: var(--landing-title-color);
    }

    &-sub-title {
      font-family: 'Gilroy', sans-serif;
      font-style: normal;
      font-weight: 300;
      font-size: 21px;
      line-height: 30px;
      color: #333333;
    }
  }

  &__icons {
    position: absolute;
    bottom: 0;
    width: 362px;
    left: var(--landing-welcome-block-info-margin-left);

    &-item {
      width: 36px;
      height: 36px;
      cursor: pointer;
    }
  }

  &__join-exchange-btn {
    @include main-button();
    width: 285px;
    height: 64px;
    font-size: 18px;
  }
}

.landing-promo-block {
  &__points-top-right {
    position: absolute;
    right: 0;
    top: 0;
  }

  &__points-bottom-left {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &__promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__promo-title {
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    text-align: center;

    color: var(--landing-title-color);
  }

  &__promo-sub-title {
    width: 810px;
    height: 52px;
    font-family: 'Gilroy', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: var(--main-text-color);
  }
}

.landing-cards-block {
  &__cards-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  &__card-item {
    display: flex;
    justify-content: center;
    flex-direction: column;

    width: 33%;
    max-width: 500px;
  }

  &__card-item-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;

    color: var(--landing-title-color);
  }

  &__card-item-text {
    padding: 0 32px 0 32px;
    line-height: 26px;
    text-align: center;
    color: #333333;
  }

  &__card-item-logo {
    margin-bottom: 30px;
  }

  &__card-item-btn {
    @include main-button();
    width: 240px;
    height: 64px;
  }
}

@include tablet-1024 {
  .landing {
    overflow: hidden;

    &__welcome-block {
      height: 518px;
    }
  }

  .header {
    &__navbar {
      margin: 0 80px;

      & button {
        margin: 0 0 0 40px;
      }
    }
  }

  .landing-welcome-block {
    &__join-exchange-btn {
      max-width: 226px;
    }

    &__icons {
      position: absolute;
      bottom: 0;
      width: 280px;
      display: flex;
      gap: 12px;
      left: 90px;
    }

    &__bottom-points-for-image {
      bottom: 0;
      right: 140px;
    }

    &__info {
      max-width: 450px;
      margin: 0 0 0 80px;
      position: absolute;
      top: 88px;

      &-title {
        & p {
          color: #375A7E;
          font-size: 40px;
          font-style: normal;
          font-weight: 700;
          margin: 0 0 20px 0;
          line-height: normal;
        }
      }

      &-sub-title {
        & p {
          color: var(--black, #333);
          line-height: 28px; /* 155.556% */
          margin-bottom: 40px;
        }
      }
    }

    &__block-top-points {
      position: absolute;
      bottom: 350px;
    }

    &__main-img {
      right: -80px;

      & img {
        width: 450px;
        height: 520px;
      }
    }

    &__main-img-line {
      right: 237px;

      & img {
        width: 160px;
        height: 520px;
      }
    }
  }
  .landing-promo-block {
    &__promo-title {
      color: #375A7E;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      max-width: 730px;
    }

    &__promo-sub-title {
      max-width: 730px;
    }

    &__points-top-right {
      top: 20px;
      right: 20px;
    }

    &__points-bottom-left {
      bottom: 20px;
      left: 20px;
    }
  }

  .landing__cards-block {
    height: 621px;
    padding: 100px 80px;
  }
  .landing-cards-block {
    &__cards-row {
      display: flex;
      gap: 40px;
      align-items: normal;
    }

    &__card-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      & p {
        margin: 0;
      }

      &-logo {
        margin: 0;
      }

      &-title {
        color: #375A7E;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &-text {
        color: var(--black, #333);
        text-align: center;
        line-height: 26px; /* 162.5% */
      }

      &-btn {
        max-width: 146px;
        max-height: 54px;
      }
    }
  }

}

@include tablet-744 {
  .header {
    &__container {
      max-width: 744px;
      padding: 0 !important;
    }
  }

  .landing {
    max-width: 744px;

    &__welcome-block {
      height: 404px;
    }

    &__btn-container {
      &__join-exchange-btn {
        max-width: 180px;
        height: 100%;
        padding: 14px 30px;
      }
    }
  }
  .landing-welcome-block {
    &__block-top-points {
      bottom: 230px;
      left: 10px;
    }

    &__bottom-points-for-image {
      right: -40px;
    }

    &__icons {
      left: 40px;

      & img {
        width: 24px;
        height: 24px;
      }
    }

    &__main-img {
      & img {
        width: 356px;
        height: 404px;
      }

      &-line {
        right: 180px;

        & img {
          height: 404px;
          width: 125px;
        }
      }
    }

    &__info {
      top: 60px;
      margin: 0;
      left: 40px;
      max-width: 356px;

      &-title {

        & p {
          color: #375A7E;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0 0 16px 0;
        }
      }

      &-sub-title {
        & p {
          color: var(--black, #333);
          line-height: normal;
          margin: 0 0 32px 0;
        }
      }
    }
  }

  .landing-promo-block {
    &__points-bottom-left {
      bottom: 16px;
      left: 16px;
    }

    &__promo-title {
      max-width: 550px;

      & p {
        color: #375A7E;
        text-align: center;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    &__promo-sub-title {
      max-width: 550px;

      & p {
        color: var(--black, #333);
        text-align: center;
        line-height: 26px; /* 162.5% */
      }
    }
  }

  .landing {
    &__cards-block {
      height: 100%;
      padding: 72px 97px;
    }
  }
  .landing-cards-block {
    &__cards-row {
      flex-direction: column;
      align-items: center;
    }

    &__card-item {
      max-width: 550px;
      width: 100%;
      padding: 0;

      &-btn {
        max-width: 216px;
        padding: 0;
      }
    }
  }
}

@include small-layout {
  .landing {
    background-color: white;
    overflow: hidden;
  }

  .landing__welcome-block {
    height: 441px;
    max-width: $mobile-width;
    width: 100%;
    background-color: white;

    p {
      margin: 0;
    }
  }

  .landing-welcome-block {
    &__info {
      margin: 0;
      position: absolute;
      top: 64px;
      left: 0;
      max-width: $mobile-width;
      width: 100%;
      display: flex;
      gap: 16px;
      flex-direction: column;

      &-title {
        color: #375A7E;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        max-width: 358px;
        width: 100%;
        margin: 0 auto;
      }

      &-sub-title {
        line-height: 26px;
        text-align: left;
        max-width: 358px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &__join-exchange-btn {
      margin: 24px 16px;
      max-width: 194px;
      height: 50px;
      width: 100%;
      color: var(--white, #FFF);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      padding: 12px 24px;
    }

    &__bottom-points-for-image {
      position: absolute;
      bottom: 0;
      left: 250px;
    }

    &__block-top-points {
      position: absolute;
      bottom: 280px;
      right: 200px;
    }

    &__icons {
      max-width: 204px;
      width: 100%;
      display: flex;
      margin: 0 0 0 16px;
      gap: 20px;
      left: 0;
    }

    &__handshake-pic {
      margin: 56px 0 0 0;
      height: 100%;
      width: 100%;
      display: block;

      & img {
        width: $mobile-width;
      }
    }

    &__main-img {
      display: none;
    }

    &__main-img-line {
      display: none;
    }
  }

  .col {
    padding: 0;
  }

  .landing__promo-block {
    margin: 13px 0 9px 0;
  }
  .landing-promo-block {
    &__promo-title {
      color: #375A7E;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__promo-sub-title {
      color: var(--black, #333);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px;
      max-width: 358px;
      width: 100%;
    }

    &__points-top-right {
      left: 160px;
      top: 0;
    }

    &__points-bottom-left {
      right: 10px;
      top: 230px;
      overflow: hidden;
      margin: 0 0 9px 0;
    }
  }

  .landing__cards-block {
    height: 100%;
    max-width: $mobile-width;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    background-image: url("../../assets/images/landing/landingCardsBackground.png");
  }

  .landing-cards-block {
    &__cards-row {
      max-width: 358px;
      width: 100%;
      margin: 0 auto 56px;
    }

    &__card-item {
      max-width: 358px;
      width: 100%;
      margin: 56px 6px 0 6px;
      gap: 24px;


      &-logo {
        margin: 0;
      }

      p {
        margin: 0;
      }

      &-title {
        color: #375A7E;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &-text {
        color: var(--black, #333);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 300;
        line-height: 26px;
        padding: 0;
      }

      &-btn {
        width: 194px;
        height: 50px;
        padding: 12px 24px;
        color: var(--white, #FFF);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
      }
    }
  }
}


