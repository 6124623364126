@import "src/assets/styles/variables";

.customers {
  &_actions {
    display: flex;
    gap: 8px;
  }
}

.customers-modal-header {
  background: var(--grey2);
  display: flex;
  align-items: center;

  &__title {
    font-size: $larger-font-size;
    color: $blue;
    font-weight: $font-bold;
    margin: 0 auto;
  }
}

.customers-modal-header .btn-close {
  margin: 0;
}
