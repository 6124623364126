@import "../../../assets/styles/variables";

.app-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  padding: 0 0.5rem;
  box-shadow: 2px 4px 20px rgba(23, 52, 84, 0.15);
  font-family: $body-font;
  flex-wrap: wrap;

  &__limit {
    margin-right: 5px;

    &_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      font-weight: $font-bold;

      .btn-primary, .show > .btn-primary.dropdown-toggle {
        color: $black;
        font-weight: $font-bold;

        &:is(:hover, :focus, :active) {
          color: $black;
          font-weight: $font-bold;
        }
      }
    }
  }

  &__pages {
    display: flex;
    justify-content: space-between;
    margin: .5rem 0;
    overflow-x: auto;

    li {
      border-radius: 100%;
      border: thin solid $red;
      color: $black;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;
        color: $black;
        text-decoration: none;
      }

      &.selected {
        background: $red;
        color: $white;
      }

      &.break {
        border: none;
      }

      &:not(&:last-child) {
        margin-right: .5rem;
      }
    }
  }

  &__info {
    color: $black;
    font-weight: $font-bold;
  }
}
