.invoice-status-modal {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  p {
    margin: 0;
  }

  &__buttons {
    display: flex;
    gap: 0.75rem;
  }

  &__button-item {
    width: 100%;
  }
}
