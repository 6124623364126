@import "../../../../assets/styles/media.scss";

.purchase-orders {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    border: 1px solid gray;
    border-radius: 5px;

    &:hover {
      background-color: var(--main-color);
      cursor: pointer;

      p {
        color: white;
      }
    }
  }

  &__info {
    &-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      flex-basis: 20%;
      gap: 4px;

      p {
        margin: 0
      }

    }

    &-container {
      display: flex;
    }

    &-header {
      text-align: left;
      font-weight: 600;

      p {
        margin: 0
      }
    }
  }
}

@include small-layout {
  .purchase-orders {
    &__info {
      &-container {
        flex-direction: column;
        gap: 8px;
      }
    }
  }
}
