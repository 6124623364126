@import "../../../../../assets/styles/variables";
@import "../../../../../assets/styles/media.scss";

.map-categories {
  min-width: 80vw;

  &-services {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    width: 100%;
  }

  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-event {
      font-weight: $font-normal !important;
    }
  }
}

@include tablet-1024 {
  .map-categories {
    &-services {
      grid-template-columns: 1fr;
    }
  }
}

.seatingplan-image {
  position: relative;

  >span {
    &.disabled {
      color: $light-grey;
    }
    &:hover +img {
      display: block;
    }
  }

  >img {
    display: none;
    max-width: 300px;
    max-height: 300px;
    position: absolute;
    top: 20px;
    left: 0;
  }
}

